import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import error404 from "../../assets/images/error/400.png";
import Languages from "../../components/Languages";
import { RouteEndpoints } from "../../services/route.service";

interface Props {
  image?: string;
  title?: string;
  body?: string;
  link?: string;
  btnText?: string;
  icon?: string;
}

function NoData({
  image,
  title,
  body,
  link,
  btnText = "Back to Home",
  icon = "ri-home-4-line",
}: Props) {
  return (
    <Col md={12} className="text-center align-self-center">
      <div className="iq-error position-relative mt-5">
        {image && (
          <Image
            src={image}
            className="img-fluid iq-error-img"
            alt="no data image"
          />
        )}
        {title && (
          <h2 className="mb-0 text-center">
            <Languages langKey={title} />
          </h2>
        )}
        {body && (
          <p className="text-center">
            {" "}
            <Languages langKey={body} />
          </p>
        )}
        {link && (
          <Link to={link} className="btn btn-primary mt-3 text-decoration-none">
            <i className={`me-1 ${icon}`} />
            <Languages langKey={btnText} />
          </Link>
        )}
      </div>
    </Col>
  );
}

export default NoData;
