import React from "react";
import {Container, Row, Col, Image} from 'react-bootstrap'
import { Link } from "react-router-dom";
import error404 from '../../assets/images/error/400.png'
import {RouteEndpoints} from "../../services/route.service";
import NoData from "./NoData";

function PageNotFound(){
    return <Container className="me-5 ps-0">
        <Row className="no-gutters height-self-center">
            <NoData title="Oops! This Page is Not Found." image={error404} body={'The requested page dose not exist.'} link={RouteEndpoints.HOME}/>
        </Row>
    </Container>
}

export default PageNotFound