import auth, {AuthState} from "./auth.state"
import setting, {SettingState} from "./setting.state"
import main, {MainState} from "./main.state"

export interface IRootState {
    auth: AuthState,
    setting: SettingState,
    main: MainState
}


const rootState: IRootState = {
    auth, setting, main
}

export default rootState