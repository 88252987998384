import { InfoLogger } from "../services/app.service";

interface srcProps {
  setGiveUserPoint: (point: boolean) => void;
  setIsUserMouseOnCard: (mouseOn: boolean) => void;
  setIsUserScrollingCard: (scrolling: boolean) => void;
  isUserScrollingCard: boolean;
  isUserMouseOnCard: boolean;
  giveUserPoint: boolean;
}

export const CalculateUserMediaPoints = (
  setGiveUserPoint: (point: boolean) => void,
  srcType: string,
  setGetUserPointType?: (type: string) => void
) => {
  setGiveUserPoint(false);

  document.querySelector(`${srcType}`)?.addEventListener("playing", () => {
    InfoLogger(srcType);
    srcType === "#video" && setGetUserPointType!(srcType);

    const mediaSrc = document.querySelector(`${srcType}`);
    //@ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    //get video duration and divide into two
    const srcDuration = Math.round(mediaSrc?.duration / 2);

    const srcCountedTime: any = setInterval(() => {
      //stop interval if current time is greater than half the time

      //@ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      Math.round((mediaSrc as HTMLInputElement)?.currentTime) > srcDuration &&
        clearInterval(srcCountedTime);

      //@ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      Math.round(mediaSrc?.currentTime) > srcDuration &&
        // InfoLogger("Stop!!!! time");

        // set user point to true when user has half of the video
        setGiveUserPoint(
          //@ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          Math.round(mediaSrc?.currentTime) === srcDuration
        );

      //@ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      //stop interval when rules are met
      Math.round(mediaSrc?.currentTime) === srcDuration &&
        clearInterval(srcCountedTime);

      //@ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      InfoLogger(Math.round(mediaSrc?.currentTime), srcDuration);

      //@ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      // Math.round(mediaSrc?.currentTime) === srcDuration &&
      //   InfoLogger("Stop!!!!");
    }, 1000);
  });
};

export const CalculateUserReadPoints = ({
  setGiveUserPoint,
  setIsUserMouseOnCard,
  setIsUserScrollingCard,
  isUserMouseOnCard,
  isUserScrollingCard,
  giveUserPoint,
}: srcProps) => {
  document.querySelector(".read-pane")?.addEventListener("mouseover", () => {
  // document.querySelector("#read")?.addEventListener("mouseover", () => {
    setIsUserMouseOnCard(true);
  });

  document.querySelector(".read-pane")?.addEventListener("mouseleave", () => {
  // document.querySelector("#read")?.addEventListener("mouseleave", () => {
    setIsUserMouseOnCard(false);
    setIsUserScrollingCard(false);
  });

  document.querySelector(".read-pane")?.addEventListener("scroll", () => {
  // document.querySelector("#read")?.addEventListener("scroll", () => {
    setIsUserScrollingCard(true);
  });

  var counter = 0;

  // check if user point is false then check
  // if mouse is over is true or scrolled and mouse over is true continue count else if mouse over stop count!

  if (
    !giveUserPoint &&
    (isUserMouseOnCard || (isUserMouseOnCard && isUserScrollingCard))
  ) {
    InfoLogger("start counter");
    const countUserRead: any = setInterval(() => {
      counter++;

      InfoLogger(counter);

      !isUserMouseOnCard && clearInterval(countUserRead) && (counter = 0);

      counter === 240 && clearInterval(countUserRead);
      // counter === 240 && InfoLogger("Stop count!!!");

      // counter === 240 && setGiveUserPoint(true);
    }, 1000);
  }
};

export const removeEventListener = (srcType: string) => {
  InfoLogger(srcType);

  document.querySelector(`${srcType}`)?.removeEventListener("playing", () => {
    InfoLogger("eventListener removed");
  });
};

export const kFormatter = (num: number) => {
  if (num < 1e3) return num;
  if (num >= 1e3 && num < 1e6) return +(num / 1e3).toFixed(1) + "K";
  if (num >= 1e6 && num < 1e9) return +(num / 1e6).toFixed(1) + "M";
  if (num >= 1e9 && num < 1e12) return +(num / 1e9).toFixed(1) + "B";
  if (num >= 1e12) return +(num / 1e12).toFixed(1) + "T";
};
