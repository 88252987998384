import {AuthResponse, UserResponse} from "../../services/api.response.service";
import constants from "../../utils/constants";
import {CheckTokenExist, GetUserData, LANGUAGES} from "../../services/app.service";
import {forEach} from "react-bootstrap/ElementChildren";

export interface HeaderState {
  open: boolean;
  active: boolean;
  notification: boolean;
  showRightChat: boolean;
}

export interface KeysState {
  recaptchaSiteKey: string;
}

export interface SettingState {
  auth: boolean;
  user: UserResponse | AuthResponse | null;
  header: HeaderState;
  loaderIds: Array<string>;
  scheme: boolean;
  sidebar: boolean;
  rtl: boolean;
  keys: KeysState;
  language: LANGUAGES;
}

const getCurrentLang = ()=>{
  // @ts-ignore
  return LANGUAGES[LANGUAGES[ (localStorage.getItem(constants.LANG) as unknown as number) ?? 0]]
}

const setting: SettingState = {
  auth: CheckTokenExist(),
  user: { ...(GetUserData() as AuthResponse|UserResponse) },
  header: {
    open: false,
    active: false,
    notification: false,
    showRightChat: false,
  },
  loaderIds: [],
  scheme: !!localStorage.getItem(constants.DARK) ?? false,
  sidebar: !!localStorage.getItem(constants.DARK) ?? false,
  rtl: false,
  keys: {
    recaptchaSiteKey: "",
  },
  language: getCurrentLang() ,
};

export default setting;

