import React from "react";
import Card from "./Card";
import { ClassResponse } from "../services/api.response.service";
import { RouteEndpoints } from "../services/route.service";
import { Link } from "react-router-dom";
import { CheckContentProviderIdExist } from "../services/app.service";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/states";
import { ReactComponent as UserSVG } from "../assets/svg/user2.svg";

interface Props {
  data: ClassResponse;
  showUnAuthModal?: () => void;
}

function ClassCard1({ data, showUnAuthModal }: Props) {
  const { ecampus_id } = useSelector(
    (state: IRootState) => state.setting.user!
  );
  return (
    <Card className="card-block card-stretch card-height product content-card-container">
      <Card.Body className="p-1 d-flex flex-column">
        <Link
          to={ RouteEndpoints.CLASS_DETAILS(String(data.link_name)) }
          className="d-flex flex-column text-decoration-none"
          onClick={showUnAuthModal!}
        >
          <div className="image-block position-relative">
            <img
              src={data.logo_url}
              className="img-fluid h-100 rounded"
              alt="product-img"
            />

            {/*     
            {!CheckContentProviderIdExist() && (
              <h6 className="price">
                {data.subscription_amount_month > 0
                  ? data.subscription_currency.concat(
                      " ",
                      String(data.subscription_amount_month)
                    )
                  : "Free"}
              </h6>
            )} */}
          </div>
          <div className="p-1 product-description mt-3 line-height-16">
            <h3 className="mb-1 fs-5 fw-normal">
              {data.name.length > 27
                ? data.name.slice(0, 27).concat("..")
                : data.name}
            </h3>

            {/* <small className="mb-0 text-body fs-7">
              {data.description.length > 38
                ? data.description.slice(0, 38).concat("...")
                : data.description}
            </small> */}

            <small className="mb-0 text-body fs-7">
              <UserSVG/>

              {data.content_provider.name}
            </small>
          </div>
        </Link>
        {/*{!CheckContentProviderIdExist() && (*/}
        {/*  <div className="d-flex justify-content-between flex-column">*/}
        {/*    <hr className="hr-horizontal" />*/}
        {/*    <div className="d-flex justify-content-between align-items-center">*/}
              {/* <div>
                <small>
                  <i className="text-warning fa fa-star" />
                  <small className="ms-1">4.3</small>
                </small>
              </div>
              <small className="d-flex align-items-center gap-1">
                <i className="fa fa-language"></i>{" "}
                <span style={{ fontSize: 10 }}>{data.language}</span>
              </small> */}
              {/*<small>*/}
              {/*  {data.category && (*/}
              {/*    <Link*/}
              {/*      to={*/}
              {/*            RouteEndpoints.CATEGORY_DETAILS(*/}
              {/*              String(data.category.link_name)*/}
              {/*            )*/}
              {/*      }*/}
              {/*      className="text-decoration-none"*/}
              {/*      onClick={showUnAuthModal}*/}
              {/*    >*/}
              {/*      {data.category.name}*/}
              {/*    </Link>*/}
              {/*  )}*/}
              {/*</small>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </Card.Body>
    </Card>
  );
}

export default ClassCard1;
