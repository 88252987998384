import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { RouteComponentProps } from "react-router";
import Main from "../../redux/actions/main.action";
import { StateKeys } from "../../redux/states/main.state";
import ApiEndpoint from "../../services/api.endpoint.service";
import { AppState, InfoLogger } from "../../services/app.service";
import PageNotFound from "./PageNotFound";
import {
  CategoryResponse,
  ClassResponse,
} from "../../services/api.response.service";
import ClassesCardVList2 from "../../components/ClassesCardVList2";
import ClassCardRList from "../../components/ClassCardRList";
import { Link } from "react-router-dom";
import AllCategoryList from "../../components/AllCategoryList";
import GoBack from "../../components/GoBack";

function HomeViewMore({ location }: RouteComponentProps) {
  const key = location.pathname.replace("/h/", "");
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);
  const pageData = state[key];
  const validKey = (key: string) =>
    key === StateKeys.RECENT ||
    key === StateKeys.TRENDING ||
    StateKeys.ACTIVE ||
    StateKeys.FELLOWS ||
    StateKeys.PRIME ||
    StateKeys.BUSINESS ||
    StateKeys.BOOK ||
    StateKeys["TEST-PREP"] ||
    StateKeys.CATEGORIES;

  useEffect(() => {
    if (
      validKey(key) &&
      pageData.pageState !== AppState.LOADED &&
      key !== StateKeys.RECENT
    ) {
      InfoLogger(key !== StateKeys.RECENT);

      dispatch(
        Main.getPageArrayData({
          stateKey: key,
          apiEndpoint: ApiEndpoint.HOME_PAGE_CATEGORY_CLASSES(key),
        })
      );
    }

    if (
      validKey(key) &&
      pageData.pageState !== AppState.LOADED &&
      key === StateKeys.RECENT
    ) {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.RECENT,
          apiEndpoint: ApiEndpoint.USER_RECENT_TOPICS,
        })
      );
    }

    if (
      validKey(key) &&
      pageData.pageState !== AppState.LOADED &&
      key === StateKeys.CATEGORIES
    ) {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.CATEGORIES,
          apiEndpoint: ApiEndpoint.ALL_CATEGORIES,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  if (!validKey(key)) {
    return <PageNotFound />;
  }

  return (
    <>
      <div className="content-page scroller-hide">
        <GoBack />

        <Row className="content-card pe-4 mt-4">
          {key !== StateKeys.RECENT && key !== StateKeys.CATEGORIES && (
            <ClassesCardVList2
              data={pageData.data as Array<ClassResponse>}
              state={pageData.pageState}
              title={key}
            />
          )}

          {key === StateKeys.CATEGORIES && (
            <AllCategoryList
              data={pageData.data as Array<CategoryResponse>}
              state={pageData.pageState}
              title={key}
            />
          )}

          {key === StateKeys.RECENT && (
            <ClassCardRList
              data={pageData.data as Array<ClassResponse>}
              state={pageData.pageState}
            />
          )}
        </Row>
      </div>
    </>
  );
}

export default HomeViewMore;
