import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import {BaseState, StateKeys} from "../../redux/states/main.state";
import { RouteComponentProps, useParams } from "react-router";
import {
  AppPageScrollDownLoadMore,
  AppState, getLanguageByDefault,
} from "../../services/app.service";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";
import Loader from "../../components/Loader";
import {
  ClassResponse,
  InterestResponse,
} from "../../services/api.response.service";
import ClassesCardVList from "../../components/ClassesCardVList";
import error404 from "../../assets/images/error/400.png";
import { RouteEndpoints } from "../../services/route.service";
import NoData from "./NoData";

function InterestClasses({ location }: RouteComponentProps) {
  let { id } = useParams<{ id: string }>();
  let { link } = useParams<{ link: string }>();

  const dispatch = useDispatch();
  const getLanguage = getLanguageByDefault();
  const state = useSelector((state: IRootState) => state.main);
  const { data, pageState, hasMore, currentPage }: BaseState<Array<ClassResponse>> =
    state[StateKeys.INTEREST_CLASSES];

    let getInterestId = () => {
        let theId = String(data[0]?.interests
            ?.find((item: InterestResponse) => (String(item.id) === id || String(item.link_name) === link))
            ?.id ?? "");

        if (theId && theId !== "" && theId !== undefined && theId !== 'undefined' && theId !== null) {
            id = theId
        }

        if (id && id !== "" && id !== undefined && id !== 'undefined' && id !== null) {
            return id
        }
        // if (link && link !== "") {
        //       return link
        // }
        // return (String((activeClass.data as ClassResponse).id));
        return '';
    }

  useEffect(() => {
    if (
      pageState !== AppState.LOADED ||
      data.length === 0 ||
      (pageState === AppState.LOADED &&
        data.length > 0 &&
        !data[0]?.interests?.find(
          (item: InterestResponse) => String(item.id) === getInterestId()
        ))
    ) {
      dispatch(
        Main.postPaginatedPageDataSuccess({
          stateKey: StateKeys.INTEREST_CLASSES,
          data: [],
          clear: true,
        })
      );
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.INTEREST_CLASSES,
          apiEndpoint: ApiEndpoint.INTEREST_CLASSES((link ?? id)),
          previousPage: 0,
        })
      );
    }
  }, [id]);

  const loadMore = () => {
    // @ts-ignore
    if (
      window.innerHeight + document.documentElement.scrollTop ===
        // @ts-ignore
        document.scrollingElement.scrollHeight &&
      hasMore &&
      // @ts-ignore
      (pageState !== AppState.LOADING || pageState !== AppState.LOADING_MORE)
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.INTEREST_CLASSES,
          apiEndpoint: ApiEndpoint.INTEREST_CLASSES((link ?? id)),
          previousPage: currentPage!,
        })
      );
    }
  };

  return (
    <div
      className="content-page scroller-hide"
      onScroll={(e) => AppPageScrollDownLoadMore(e, loadMore)}
    >
      <Row>
        {(pageState === AppState.LOADED ||
          pageState === AppState.LOADING_MORE) && (
          <>
            {data.length ? (
                <div className="content-card">
                      <ClassesCardVList
                        data={data as Array<ClassResponse>}
                        state={pageState}
                        fullTitle={data[0]?.interests
                          ?.find((item: InterestResponse) => String(item.id) === getInterestId())
                          ?.name.concat(` ${getLanguage.classes}`)}
                      />
                </div>
            ) : (
              <NoData
                title="noClassInterest"
                link={RouteEndpoints.INTERESTS}
                btnText="backToInterest"
              />
            )}
          </>
        )}
        <Col md={12}>
          {(pageState === AppState.LOADING_MORE ||
            pageState === AppState.LOADING) && <Loader noCard imageLoader />}
        </Col>
      </Row>
    </div>
  );
}

export default InterestClasses;
