import React from "react";
import { useHistory } from "react-router";

interface props {
  previousPage?: (previous: any) => void;
}

const GoBack = ({ previousPage }: props) => {
  const history = useHistory();
  return (
    <>
      {" "}
      <div
        onClick={() => (previousPage ? previousPage(null!) : history.goBack())}
        className="pb-2"
        style={{ cursor: "pointer", width: "fit-content" }}
      >
        <h3>
          <i className="las la-arrow-left"></i>{" "}
        </h3>
      </div>{" "}
    </>
  );
};

export default GoBack;
