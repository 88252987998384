import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Col } from "react-bootstrap";
import ClassCard1 from "./ClassCard1";
import { CategoryResponse } from "../services/api.response.service";
import { AppState } from "../services/app.service";
import Loader from "./Loader";
import Languages from "./Languages";
import NoData from "../pages/main/NoData";
import { RouteEndpoints } from "../services/route.service";

interface Props {
  title: string;
  viewAllLink?: string;
  data: Array<CategoryResponse>;
  state: AppState;
}

const CategoryList = ({ title, viewAllLink, data, state }: Props) => {
  const location = useLocation();

  return (
    <>
      <Col lg={12} className="row m-0 p-0">
        <Col sm={12} className="d-flex justify-content-between mt-1">
          <div className="header-title">
            <h4 className="card-title" style={{ fontWeight: 500 }}>
              <Languages langKey={title} />
            </h4>
          </div>
          {viewAllLink && state === AppState.LOADED && (
            <div className="card-header-toolbar d-flex align-items-center">
              <Link to={viewAllLink}>
                <Languages langKey={"viewAll"} />{" "}
                <i className="las la-angle-right"></i>
              </Link>
            </div>
          )}
        </Col>
        <Col
          sm={12}
          className={` ${
            location.pathname === "/home"
              ? "cards-container flex-row flex-nowrap"
              : ""
          }  m-0 p-0`}
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${data.length}, 145px)`,
            gridGap: "30px",
          }}
        >
          {state === AppState.LOADING ? (
            <Loader noCard imageLoader />
          ) : data.length > 0 ? (
            data.map((item) => (
              <Col key={item.id} className="mt-1">
                <Link
                  // to={RouteEndpoints.CATEGORY_CLASSES(String(item?.id))}
                  to={RouteEndpoints.CATEGORY_DETAILS(`${item?.link_name}`)}
                  className="category-btn d-flex justify-content-center align-items-center"
                >
                  {item?.name}
                </Link>
              </Col>
            ))
          ) : (
            <NoData title={"noCatAvailable"} />
          )}
        </Col>
      </Col>
    </>
  );
};

export default CategoryList;
