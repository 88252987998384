import React, {FormEvent, useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {useAuthState} from "react-firebase-hooks/auth";
import {useDispatch, useSelector} from "react-redux";
import Main from "../redux/actions/main.action";
import {IRootState} from "../redux/states";
import {StateKeys} from "../redux/states/main.state";
import ApiEndpoint from "../services/api.endpoint.service";
import {UserResponse} from "../services/api.response.service";
import {AppState, getLanguageByDefault, LANGUAGES} from "../services/app.service";
import {auth} from "../services/firebase.service";
import {Countries} from "../utils/constants";
import Languages from "./Languages";

interface props {
  show: boolean;
  state: number;
  profile: UserResponse;
}

const UpdateOAuthProfile = ({ show, state, profile }: props) => {
  const dispatch = useDispatch();
  const [user] = useAuthState(auth);
  const getLanguage = getLanguageByDefault()

  const [newProfile, setProfile] = useState({
    name: "",
    first_name: "",
    last_name: "",
    country: "",
    city: "",
    about: "",
    referral_id: "",
    institute: "",
    email: "",
  });

  useEffect(() => {
    setProfile({
      ...newProfile,
      name: profile.name,
      first_name: profile.first_name,
      last_name: profile.last_name,
      email: user?.email as unknown as string,
    });
  }, []);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(
      Main.postPageData({
        stateKey: StateKeys.PROFILE,
        apiEndpoint: ApiEndpoint.UPDATE_USER,
        req: newProfile,
      })
    );
  };

  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title>
            <Languages langKey="submit" />{" "}
          </Modal.Title>
        </Modal.Header>
        <Form className="mt-2" onSubmit={handleSubmit}>
          <Modal.Body>
            <Row className="w-100">
              <Col md="6" className="mb-1">
                <Form.Label className="mb-0">
                  <Languages langKey="country" />{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  onChange={({ target: { value } }) =>
                    setProfile({ ...newProfile, country: value })
                  }
                  disabled={state === AppState.LOADING}
                  required
                  className={`form-control ${
                    state === AppState.ERROR_INPUT && "is-invalid"
                  }`}
                >
                  {Countries.map((country: string,i) => (
                    <option value={country} key={i}> {country}</option>
                  ))}
                </Form.Select>
              </Col>
              <Col md="6" className="mb-1">
                <Form.Label className="mb-0">
                  <Languages langKey="city" />{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  className={`${
                    state === AppState.ERROR_INPUT && "is-invalid"
                  }`}
                  onChange={(event) =>
                    setProfile({ ...newProfile, city: event.target.value })
                  }
                  disabled={state === AppState.LOADING}
                />
              </Col>

              <Col md="12" className="mb-1">
                <Form.Label className="mb-0">
                  {" "}
                  <Languages langKey="institute" />{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(event) =>
                    setProfile({ ...newProfile, institute: event.target.value })
                  }
                  disabled={state === AppState.LOADING}
                />
              </Col>
              <Col md="12" className="mb-1">
                <Form.Label className="mb-0">
                  {" "}
                  <Languages langKey="referralID" />{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(event) =>
                    setProfile({
                      ...newProfile,
                      referral_id: event.target.value,
                    })
                  }
                />
              </Col>
              <Col md="12" className="mb-1">
                <Form.Label className="mb-0">
                  <Languages langKey="about" />{" "}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={
                    getLanguage.tellUsAboutYourself
                  }
                  onChange={(event) =>
                    setProfile({ ...newProfile, about: event.target.value })
                  }
                  disabled={state === AppState.LOADING}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-inline-block w-100">
              <Button type="submit" className="btn-primary float-end mt-1 pt-1">
                {state === AppState.LOADING && (
                  <Spinner animation={"border"} size={"sm"} className="me-1" />
                )}
                <Languages langKey="submit" />{" "}
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateOAuthProfile;
