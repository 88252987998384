import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import {
  AppPageScrollDownLoadMore,
  AppState,
} from "../../services/app.service";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";
import { ContentProviderResponse } from "../../services/api.response.service";
import ContentProvidersCardList from "../../components/ContentProvidersCardList";
import Languages from "../../components/Languages";

function ContentProviders() {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);
  const {
    data,
    pageState,
    hasMore,
    currentPage,
  }: BaseState<Array<ContentProviderResponse>> =
    state[StateKeys.CONTENT_PROVIDERS];

  useEffect(() => {
    if (pageState !== AppState.LOADED && pageState !== AppState.LOADING) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CONTENT_PROVIDERS,
          apiEndpoint: ApiEndpoint.ALL_ACTIVE_CONTENT_PROVIDERS,
          previousPage: currentPage!,
        })
      );
    }
  }, []);

  const loadMore = () => {
    // @ts-ignore
    if (
      // @ts-ignore
      window.innerHeight + document.documentElement.scrollTop ===
        // @ts-ignore
        document.scrollingElement.scrollHeight &&
      hasMore && // @ts-ignore
      (pageState !== AppState.LOADING || pageState !== AppState.LOADING_MORE)
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CONTENT_PROVIDERS,
          apiEndpoint: ApiEndpoint.ALL_ACTIVE_CONTENT_PROVIDERS,
          previousPage: currentPage!,
        })
      );
    }
  };

  return (
    <div
      className="content-page scroller-hide"
      onScroll={(e) => AppPageScrollDownLoadMore(e, loadMore)}
    >
      <Row className="content-card pe-4 mt-4*">
        <h5
          style={{
            fontSize: "24px",
            fontWeight: 400,
          }}
        >
          <Languages langKey="contentProvider" />
        </h5>
        <ContentProvidersCardList data={data} state={pageState} />
        <Col md={12}>
          {hasMore &&
            !(
              pageState === AppState.LOADING ||
              pageState === AppState.LOADING_MORE
            ) && (
              <div
                className={`w-100 text-center shadow-xss rounded-xxl border-0 pt-3`}
              >
                <button
                  className="text-decoration-none btn btn-primary"
                  onClick={() => loadMore()}
                >
                  <Languages langKey="loadMore" />
                </button>
              </div>
            )}
        </Col>
      </Row>
    </div>
  );
}

export default ContentProviders;
