import rootState from "../states";
import { types } from "../actions/auth.action";
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = rootState.auth, { type, payload }: any) {
  switch (type) {
    //Login
    case types.SIGN_IN_STATE:
      return { ...state, signIn: { ...state.signIn, state: payload } };
    case types.SIGN_IN_MESSAGE:
      return { ...state, signIn: { ...state.signIn, message: payload } };

    //Register
    case types.SIGN_UP_STATE:
      return { ...state, signUp: { ...state.signUp, state: payload } };
    case types.SIGN_UP_MESSAGE:
      return { ...state, signUp: { ...state.signUp, message: payload } };

    //OAuth
    case types.OAUTH_STATE:
      return { ...state, oAuth: { ...state.oAuth, state: payload } };
    case types.OAUTH_MESSAGE:
      return { ...state, oAuth: { ...state.oAuth, message: payload } };

    //Register
    case types.REGISTER_LOADER:
      return { ...state, register: { ...state.register, loader: payload } };
    case types.REGISTER_ERROR:
      return { ...state, register: { ...state.register, error: payload } };
    case types.REGISTER_ERROR_INPUT:
      return { ...state, register: { ...state.register, errorInput: payload } };
    case types.REGISTER_MESSAGE:
      return { ...state, register: { ...state.register, message: payload } };
    case types.REGISTER_STATE:
      return { ...state, register: { ...state.register, state: payload } };

    //Forgot password
    case types.FORGOT_PASSWORD_STATE:
      return {
        ...state,
        forgotPassword: { ...state.forgotPassword, state: payload },
      };
    case types.FORGOT_PASSWORD_MESSAGE:
      return {
        ...state,
        forgotPassword: { ...state.forgotPassword, message: payload },
      };

    //Delete account
    case types.DELETE_ACCOUNT_STATE:
      return {
        ...state,
        deleteAccount: { ...state.deleteAccount, state: payload },
      };
    case types.DELETE_ACCOUNT_MESSAGE:
      return {
        ...state,
        deleteAccount: { ...state.deleteAccount, message: payload },
      };

    //Login
    case types.TELECEL_SIGN_IN_STATE:
      return { ...state, telecel: { ...state.telecel, state: payload } };
    case types.TELECEL_SIGN_IN_MESSAGE:
      return { ...state, telecel: { ...state.telecel, message: payload } };
    case types.TELECEL_SIGN_IN_SHOW_CODE:
      return { ...state, telecel: { ...state.telecel, showCode: payload } };
    case types.TELECEL_SIGN_IN_NEW_USER:
      return { ...state, telecel: { ...state.telecel, newUser: payload } };
    default:
      return { ...state };
  }
}
