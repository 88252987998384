import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "./redux/states";
import { Redirect, Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { RouteEndpoints, Routes } from "./services/route.service";

import "./assets/scss/ec.scss";
import DashboardLayout from "./layouts/DashboardLayout";
import { BaseState, StateKeys } from "./redux/states/main.state";
import { UserResponse } from "./services/api.response.service";
import UpdateOAuthProfile from "./components/UpdateOAuthProfile";
import { SendAnalytics } from "./services/app.service";

function App() {
  const { auth } = useSelector((state: IRootState) => state.setting);
  const state = useSelector((state: IRootState) => state.main);

  const { data, pageState }: BaseState<UserResponse> = state[StateKeys.PROFILE];
  const [showProfileUpdate, setShowProfileUpdate] = useState(false);

  const { country, city } = data;

  useEffect(() => {
    //check if user's profile is updated on signup with oAuth if not prompt them to update it
    country?.length === 0 && city?.length === 0 && auth
      ? setShowProfileUpdate(true)
      : setShowProfileUpdate(false);
  }, [data]);

  useEffect(() => {
    SendAnalytics()!;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <BrowserRouter>
      <Switch>
        {
          // eslint-disable-next-line array-callback-return
          Routes.map(
            (route, i) =>
              !(auth && !route.private) && (
                <Route
                  key={i}
                  exact={route.exact}
                  path={route.path}
                  render={(props) => (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  )}
                />
              )
          )
        }

        {auth && <Route path="/" component={DashboardLayout} />}
        {!auth && <Redirect to={{ pathname: RouteEndpoints.SIGN_IN }} />}
      </Switch>
      <UpdateOAuthProfile
        show={showProfileUpdate}
        state={pageState}
        profile={data}
      />
    </BrowserRouter>
  );
}

export default App;
