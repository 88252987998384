import { CheckContentProviderIdExist } from "./app.service";

const ApiEndpoint = {
  //Auth
  AUTH_SIGN_IN: "auth/signin",
  AUTH_SIGN_UP: "auth/signup",
  AUTH_REGISTER: "auth/register",
  AUTH_FORGOT_PASSWORD: "auth/password/forgot",
  AUTH_TELECEL_SIGN_IN: "auth/register/telecel",
  //User
  USER: "user",
  UPDATE_USER: "user",
  UPDATE_USER_IMAGE: "user/image",
  UPDATE_PASSWORD: "user/password",
  UPDATE_INTERESTS: "user/interests",
  DELETE_USER: "user/delete",
  USER_INTERESTS: "user/interests",
  USER_RECENT_TOPICS: "user/recents",
  USER_SUBSCRIPTIONS: "user/subscriptions",
  USER_PAID_SUBSCRIPTIONS: "user/subscriptions/payments",
  USER_POINTS: "user/points",
  USER_POINTS_CLASS: "user/points/class",
  USER_POINTS_CLASS_DETAILS: (id: number) => `user/points/class?class_id=${id}`,
  POINTS: "points",
  USER_POINTS_DETAILS: "user/points/test",

  //Contents Providers
  ALL_ACTIVE_CONTENT_PROVIDERS: "content-providers",
  ACTIVE_CONTENT_PROVIDERS_LINK: (link: string) => `content-providers/link/${link}`,
  ACTIVE_CONTENT_PROVIDERS: (id: string | number) => `content-providers/${id}`,
  CONTENT_PROVIDERS_CLASSES: (id: string | number, cp_id: string | number | undefined | null) => `content-providers/${id}/classes?content_provider_id=${cp_id}`,

  //Public
  PUBLIC_ALL_ACTIVE_CONTENT_PROVIDERS: "public/content-providers",
  PUBLIC_CLASSES: "public/classes/public",
  PAID_CLASSES: "public/classes/paid",
  SHS_CLASSES: "public/classes/shs",
  JHS_CLASSES: "public/classes/jhs",
  // JHS_CLASSES: "public/classes/jhs",

  //Classes
  // ACTIVE_CLASSES: "classes/active",
  ACTIVE_CLASSES: (search_word: string) =>
    `classes/active?search_word=${search_word}`,
  ACTIVE_CLASS: (id: string | number) => `classes/${id}`,
  ACTIVE_CLASS_LINK: (link: string) => `classes/link/${link}`,
  HOME_PAGE_CATEGORY_CLASSES: (category: string) => `classes/${category}`,
  CATEGORY_CLASSES_LINK: (link: string) => `categories/link/${link}`,
  CATEGORY_CLASSES: (id: string | number) => `categories/${id}/classes`,
  SUBSCRIBE_CLASS: (id: number) => `classes/${id}/subscribe`,
  UNSUBSCRIBE_CLASS: (id: number) => `classes/${id}/unsubscribe`,

  //Content
  CLASS_COURSES: (id: string | number) => `classes/${id}/courses`,
  COURSE_TOPICS: (id: string | number) => `classes/content/${id}/topics`,
  COURSE_CONTENT: (id: string | number) => `classes/content/${id}`,
  CLASS_CONTENT_DISCUSSION: (id: string | number) => `classes/content/${id}/posts`,
  CONTENT_DISCUSSION: (id: string | number) => `contents/${id}/posts`,
  CONTENT_DISCUSSION_COMMENT: (id: string | number) => `contents/posts/${id}/comments`,

  //Test
  CLASS_TESTS: (id: string | number) => `classes/${id}/tests`,
  CLASS_TEST: (id: number) => `tests/${id}`,
  CLASS_TEST_RESULTS: (id: number) => `tests/${id}/results`,

  //Engagements
  ANNOUNCEMENTS: CheckContentProviderIdExist()
    ? `announcements`
    : "announcements",
  EVENTS: "events",

  //Helpers
  ALL_CATEGORIES: "categories",
  ALL_INTERESTS: "interests",
  INTEREST_CLASSES_LINK: (link: string) => `interests/link/${link}`,
  INTEREST_CLASSES: (id: string | number) => `interests/${id}/classes`,

  //Search
  // GENERAL_SEARCH: "search",
  GENERAL_SEARCH: (searchText: string) => `search?search_text=${searchText}`,
  ACCESS_CONTENT: "search/access",
};

export default ApiEndpoint;
