import {getLanguage, getLanguageByDefault} from "../services/app.service";
import {useSelector} from "react-redux";
import {IRootState} from "../redux/states";

interface props {
  langKey: string;
}

const Languages = ({ langKey }: props) => {
  const { language } = useSelector((state: IRootState) => state.setting);

  return getLanguage(langKey,language)
};

export default Languages;
