import {AuthResponse, UserResponse} from "../../services/api.response.service";
import {LANGUAGES} from "../../services/app.service";

export const types = {
  USER: "USER",
  HEADER_OPEN: "HEADER_OPEN",
  HEADER_ACTIVE: "HEADER_ACTIVE",
  HEADER_NOTIFICATION: "HEADER_NOTIFICATION",
  HEADER_SHOW_RIGHT_CHAT: "HEADER_SHOW_RIGHT_CHAT",
  ADD_LOADER_ID: "ADD_LOADER_ID",
  REMOVE_LOADER_ID: "REMOVE_LOADER_ID",
  DARK_MODE: "DARK_MODE",
  SIDEBAR_SHOW: "SIDEBAR_SHOW",
  RECAPTCHA_SITE_KEY: "RECAPTCHA_SITE_KEY",
  LANGUAGE: "LANGUAGE",
};

const Setting = {
  setUser: (payload: UserResponse|AuthResponse) => ({ type: types.USER, payload }),
  headerToggleOpen: (payload: boolean) => ({
    type: types.HEADER_OPEN,
    payload,
  }),
  headerToggleActive: (payload: boolean) => ({
    type: types.HEADER_ACTIVE,
    payload,
  }),
  headerToggleNotification: (payload: boolean) => ({
    type: types.HEADER_NOTIFICATION,
    payload,
  }),
  headerToggleShowRightChat: (payload: boolean) => ({
    type: types.HEADER_SHOW_RIGHT_CHAT,
    payload,
  }),
  addLoaderId: (payload: string) => ({ type: types.ADD_LOADER_ID, payload }),
  removeLoaderId: (payload: string) => ({
    type: types.REMOVE_LOADER_ID,
    payload,
  }),
  setDarkMode: (payload: boolean) => ({ type: types.DARK_MODE, payload }),
  showSideBar: (payload: boolean) => ({ type: types.SIDEBAR_SHOW, payload }),
  setRecaptchaSiteKey: (payload: string) => ({
    type: types.RECAPTCHA_SITE_KEY,
    payload,
  }),
  setLanguage: (payload: LANGUAGES) => ({
    type: types.LANGUAGE,
    payload,
  }),
};

export default Setting;
