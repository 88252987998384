import React from "react";

const Forum = () => {
  return (
    <>
      {" "}
      <section id="Forum">
        <h1>Forum</h1>
      </section>{" "}
    </>
  );
};

export default Forum;
