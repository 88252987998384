import React from "react";
import { AnnouncementResponse } from "../services/api.response.service";
import { ellipsisText } from "../services/app.service";
import { Card, Col, Row } from "react-bootstrap";

interface Props {
  data: AnnouncementResponse;
}

function AnnouncementCard({ data }: Props) {
  return (
    <Card className="card-block card-stretch card-height blog-grid shadow-none">
      <Card.Body className="p-0">
        <Row className="align-items-center">
          <Col sm={12}>
            <div
              className="blog-description shadow-none p-3 row gap-4"
              style={{ cursor: "pointer" }}
            >
              <div
                className="blog-img col-3"
                style={{
                  width: "127px",
                  height: "127px",
                  // backgroundColor: "red",
                  borderRadius: "5px",
                  // backgroundImage: `url(${data?.resource_url})`,
                }}
              >
                <Card.Img
                  variant="top"
                  src={data?.resource_url}
                  className="w-100"
                  style={{ height: "inherit" }}
                />
              </div>
              <div className="col-9 d-flex flex-column gap-2">
                <small className="d-flex align-items-center gap-2">
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2917 2.16797H2.70833C2.11002 2.16797 1.625 2.65299 1.625 3.2513V10.8346C1.625 11.4329 2.11002 11.918 2.70833 11.918H10.2917C10.89 11.918 11.375 11.4329 11.375 10.8346V3.2513C11.375 2.65299 10.89 2.16797 10.2917 2.16797Z"
                      stroke="#9E9E9E"
                      strokeWidth="0.928571"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.625 5.41797H11.375"
                      stroke="#9E9E9E"
                      strokeWidth="0.928571"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.6665 1.08203V3.2487"
                      stroke="#9E9E9E"
                      strokeWidth="0.928571"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.3335 1.08203V3.2487"
                      stroke="#9E9E9E"
                      strokeWidth="0.928571"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {data?.date}
                </small>
                <div className="date mb-1">
                  <h2
                    style={{ fontSize: "16px", fontWeight: 600 }}
                    // className="text-primary"
                  >
                    {ellipsisText(data.title, 40)}{" "}
                  </h2>
                </div>
                <p
                  className="mb-2"
                  style={{ fontSize: "12px", fontWeight: 500 }}
                >
                  {ellipsisText(data.details, 50)}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>

    // <Card className="card-block card-stretch card-height blog-grid">
    //   <Card.Body className="p-0">
    //     <Row className="align-items-center">
    //       <Col sm={12}>
    //         <div className="blog-description shadow-none p-3 text-center">
    //           <div className="date mb-1">
    //             <span className="text-primary">
    //               {ellipsisText(data.title, 40)}
    //             </span>
    //           </div>
    //           <h5 className="mb-2">{ellipsisText(data.details, 50)}</h5>
    //         </div>
    //       </Col>
    //     </Row>
    //   </Card.Body>
    // </Card>
  );
}

export default AnnouncementCard;
