import React from "react";

interface props {
  icon: string | JSX.Element;
  title: string;
  subTitle?: string;
  button?: JSX.Element;
}

const NotFound = ({ icon, title, subTitle, button }: props) => {
  return (
    <>
      <div className="col-4 mx-auto">
        <div className="d-flex flex-column justify-content-center align-items-center gap-2">
          <div
            className="not-found rounded-pill position-relative d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "rgba(218, 55, 50, 0.05)",
              fontSize: "3em",
              color: "#DA3732",
              width: "180px",
              height: "180px",
            }}
          >
            {typeof icon === "string" ? (
              <i className={`las ${icon}`}></i>
            ) : (
              icon
            )}
          </div>
          <p
            style={{
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            {title}
          </p>
          <small>{subTitle}</small>

          {button}
        </div>
      </div>
    </>
  );
};

export default NotFound;
