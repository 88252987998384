import React from "react";
import {Col, Offcanvas, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap";
import { Link } from "react-router-dom";
import {CourseResponse, TestResponse, TestResultResponse} from "../services/api.response.service";
import {
  AppState,
  CheckContentProviderIdExist,
  GetFullURL, getLanguageByDefault,
} from "../services/app.service";
import { RouteEndpoints } from "../services/route.service";
import Languages from "./Languages";
import {BaseState} from "../redux/states/main.state";
import Loader from "./Loader";
import Card from "./Card";
import moment from "moment";

interface props {
  hasSubscribed?: boolean;
  hasPaid?: boolean;
  paymentUrl?: string;
  subscriptionState?: number;
  subscribeFunction?: () => void;
  setShowTestDetails: (course: undefined | TestResponse) => void;
  showTestDetails: TestResponse;
  results:  BaseState<Array<TestResultResponse>>;
}

const TestOffCanvas = ({
  hasSubscribed,
  hasPaid,
  paymentUrl,
  subscriptionState,
  subscribeFunction,
  setShowTestDetails,
  showTestDetails, results
}: props) => {
  return (
    <>
      <section id="TestOffCanvas">
        <Offcanvas
          show={showTestDetails}
          onHide={() => setShowTestDetails(undefined)}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              {(showTestDetails as TestResponse)?.name}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="mt-2 text-body">
              <p className="mb-0"> {showTestDetails?.instructions} </p>
            </div>
            <p className="mt-2">
              <Languages langKey="duration" />{" "}
              <span className="text-primary">
                {showTestDetails?.duration} m
              </span>
            </p>
            <p className="mt-2">
              {getLanguageByDefault().questions}{" "}
              <span className="text-primary">
                {showTestDetails?.question_limit}
              </span>
            </p>
            <div className="mt-2 d-flex justify-content-between mb-3" />
            {hasSubscribed !== undefined && (
              <>
                {hasSubscribed ?
                  hasPaid || CheckContentProviderIdExist() ? (
                      <a
                        href={GetFullURL(showTestDetails?.test_url)}
                        target="_blank"
                        className="text-decoration-none btn btn-primary"
                        rel="noreferrer"
                      >
                        <Languages langKey="startTest" />{" "}
                      </a>
                    ):
                    (
                      <a
                        href={paymentUrl!}
                        target="_blank"
                        className="text-decoration-none btn btn-primary"
                        rel="noreferrer"
                      >
                        <Languages langKey="payToAccessContent" />{" "}
                      </a>
                    )
                  : (
                  <button
                    className="text-decoration-none btn btn-primary"
                    onClick={() =>
                      CheckContentProviderIdExist()
                        ? null
                        : subscribeFunction!()
                    }
                    disabled={
                      subscriptionState === AppState.LOADING ||
                      CheckContentProviderIdExist()
                    }
                  >
                    {subscriptionState === AppState.LOADING && (
                      <Spinner
                        animation={"border"}
                        size={"sm"}
                        className="me-1"
                      />
                    )}
                    {CheckContentProviderIdExist() ? (
                      <Languages langKey="contentAdminTest" />
                    ) : (
                      <Languages langKey="subToStartLearn" />
                    )}
                  </button>
                )}
              </>
            )}

            {!showTestDetails?.test_class?.is_subscribed &&
              hasSubscribed === undefined && (
                <>
                  <Link
                    to={RouteEndpoints.CLASS_COURSES(
                      String(showTestDetails?.test_class?.id)
                    )}
                    className="text-decoration-none btn btn-primary"
                  >
                    <Languages langKey="subToStartTest" />
                  </Link>
                </>
              )}
            <Row>
              {(results.pageState === AppState.LOADED ||
                results.pageState === AppState.LOADING_MORE) && (
                <>
                  {results.data ? (
                    <>
                      <Col md={12}>
                        <Row className="mt-3">
                          <h6>Previous test results</h6>
                          {results.data.length > 0 ? (
                            (results.data).map(
                              (item, i) => (
                                <Col
                                  key={i}
                                  sm={12}
                                  className="mt-3"
                                >
                                  <Card className="course-card">
                                    <Card.Body>
                                      <div className="d-flex ">
                                        <div className={`text-white px-2 py-1 rounded-3 me-2 d-flex flex-column align-content-center justify-content-center`}
                                             style={{background: item.marks >= showTestDetails?.question_limit? 'rgba(76, 175, 80, 0.2)' : 'rgba(234, 152, 63, 0.2)'}}
                                        >
                                          <span className="d-block line-height mt-0 mx-auto"
                                                style={{color: item.marks >= showTestDetails?.question_limit? '#4CAF50' : '#DF6438'}}
                                          >
                                          <i className="las la-trophy fs-4" />
                                        </span>
                                          <h5 style={{color: item.marks >= showTestDetails?.question_limit? '#4CAF50' : '#DF6438'}}>{`${item.marks}/${showTestDetails?.question_limit}`}</h5>
                                        </div>
                                        <div className="d-flex flex-column">
                                          <div className="d-flex">
                                        <span className="d-block line-height mt-0 me-1" style={{color: '#3C3C3B'}}>
                                          <i className="las la-calendar-day fs-4" />
                                        </span>
                                            <h6>{moment(item.created_at).format('ddd, Do MMM YYYY')}</h6>
                                          </div>
                                          <div className="d-flex mt-1">
                                        <span className="d-block line-height mt-0 me-1" style={{color: '#3C3C3B'}}>
                                          <i className="las la-stopwatch fs-4" />
                                        </span>
                                            <h6>{`${item.time_taken}mins/${showTestDetails?.duration}mins`}</h6>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-end">
                                        <h6 className={`text-white px-2 py-1 rounded-3`}
                                            style={{backgroundColor: item.marks >= showTestDetails?.question_limit? '#4CAF50' : '#DF6438'}}
                                        >
                                          {item.marks >= showTestDetails?.question_limit? 'Passed' : 'Failed'}</h6>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              )
                            )
                          ) : (
                            <></>
                          )}

                        </Row>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <Col md={12}>
                {results.pageState === AppState.LOADING && (
                  <Loader noCard imageLoader />
                )}
              </Col>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>
      </section>
    </>
  );
};

export default TestOffCanvas;
