import React, { useState } from "react";
import { ClassContentDiscussionResponse } from "../../services/api.response.service";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import { AppState } from "../../services/app.service";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import { IRootState } from "../../redux/states";
import { useDispatch, useSelector } from "react-redux";
import ApiEndpoint from "../../services/api.endpoint.service";
import Main from "../../redux/actions/main.action";
import NoData from "../../pages/main/NoData";
import DiscussionReplies from "./DiscussionReplies";
import moment from "moment";

interface props {
  discussions: BaseState<Array<ClassContentDiscussionResponse>>;
  contentID: string | number;
  //   getDiscussionComments;
}

const Discussions = ({ discussions, contentID }: props) => {
  const { user } = useSelector((state: IRootState) => state.setting);
  const dispatch = useDispatch();

  const [discussion, setDiscussion] =
    useState<ClassContentDiscussionResponse>();
  const [post, setPost] = useState<string>("");

  const submit = (e: any) => {
    e.preventDefault();

    if (post!.length <= 0) {
      return;
    }

    dispatch(
      Main.postPageData({
        stateKey: StateKeys.CLASS_CONTENT_DISCUSSION,
        apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION(Number(contentID)),
        req: { post },
        append: "start",
      })
    );

    discussions.pageState === AppState.LOADED && setPost("");
  };

  const conditions: string[] = ["minutes", "minute"];

  return (
    <>
      {discussions.data.length > 0 ? (
        <div className="p-3 comment-container  overflow-scroll">
          {discussions.data.map((discuss: ClassContentDiscussionResponse) => (
            <Card key={discuss.id} className="px-4">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-column gap-1 mt-3">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                      <img
                        className="discuss-profile"
                        style={{
                          width: "40px",
                          height: "40px",
                          display: "flex",
                          overflow: "hidden",
                          borderRadius: "50%",
                        }}
                        src={discuss.user.image_url}
                        alt={discuss.user.name}
                      />{" "}
                      <h5 style={{ fontWeight: 500 }}>{discuss.user.name}</h5>
                    </div>
                    <DropdownButton
                      drop={"start"}
                      variant={"transparent"}
                      title={<i className="las la-ellipsis-v"></i>}
                    >
                      <Dropdown.Item eventKey="1">Report</Dropdown.Item>
                    </DropdownButton>
                  </div>

                  <p className="p-0 m-0">{discuss?.post}</p>
                </div>

                <div className="d-flex justify-content-end align-items-center gap-4">
                  {discuss.total_comments > 0 && (
                    <p
                      style={{
                        color: "#DF6438",
                      }}
                    >
                      {discuss.total_comments}{" "}
                      {discuss.total_comments > 1 ? "Replies" : "Reply"}
                    </p>
                  )}
                  <p
                    style={{
                      color: "#DF6438",
                      cursor: "pointer",
                    }}
                    className="d-flex align-items-center gap-1"
                    onClick={() => setDiscussion(discuss)}
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1481_40774)">
                        <path
                          d="M5.50006 0.916992C4.89817 0.916992 4.30217 1.03554 3.74609 1.26588C3.19002 1.49621 2.68476 1.83382 2.25915 2.25942C1.39961 3.11896 0.916727 4.28475 0.916727 5.50033C0.912721 6.55868 1.27917 7.58507 1.95256 8.40158L1.03589 9.31824C0.972297 9.3827 0.929215 9.46454 0.912085 9.55345C0.894956 9.64236 0.904545 9.73436 0.939644 9.81783C0.977712 9.90029 1.03943 9.96959 1.11695 10.0169C1.19447 10.0643 1.28431 10.0875 1.37506 10.0837H5.50006C6.71564 10.0837 7.88142 9.60077 8.74097 8.74123C9.60051 7.88169 10.0834 6.7159 10.0834 5.50033C10.0834 4.28475 9.60051 3.11896 8.74097 2.25942C7.88142 1.39988 6.71564 0.916992 5.50006 0.916992ZM5.50006 9.16699H2.47964L2.90589 8.74074C2.99126 8.65487 3.03917 8.5387 3.03917 8.41762C3.03917 8.29653 2.99126 8.18037 2.90589 8.09449C2.30575 7.49501 1.93202 6.70599 1.84838 5.86186C1.76474 5.01772 1.97637 4.1707 2.44721 3.46511C2.91805 2.75951 3.61896 2.23899 4.43055 1.99223C5.24213 1.74547 6.11416 1.78774 6.89808 2.11183C7.68199 2.43592 8.32928 3.02178 8.72968 3.76961C9.13007 4.51743 9.25879 5.38095 9.09391 6.21304C8.92903 7.04512 8.48074 7.7943 7.82543 8.33294C7.17012 8.87157 6.34833 9.16633 5.50006 9.16699Z"
                          fill="#DF6438"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1481_40774">
                          <rect width="11" height="11" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    Add a reply
                  </p>
                  <p style={{ color: "#9E9E9E" }}>
                    {conditions.some((el) =>
                      moment(new Date(discuss?.created_at))
                        .startOf("minute")
                        .fromNow()
                        .includes(el)
                    )
                      ? moment(new Date(discuss?.created_at))
                          .startOf("minute")
                          .fromNow()
                      : moment(new Date(discuss?.created_at)).format("ll")}
                  </p>{" "}
                </div>
              </div>
            </Card>
          ))}
        </div>
      ) : (
        <NoData title="DiscussionNotFound" />
      )}

      <div className="position-absolute bottom-0 w-100 add-comment">
        <form onSubmit={submit}>
          <div className="row  align-items-center px-4 mt-2 position-relative">
            <div className="d-flex gap-3 align-items-center col-2">
              <div
                className="profile-picture rounded-pill"
                style={{ backgroundImage: `url(${user?.image_url})` }}
              ></div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-emoji-smile"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
              </svg>
            </div>

            <div className="col-9">
              <textarea
                className="form-control p-2"
                name="post"
                id="post"
                placeholder="Add a post..."
                onChange={(e) => setPost(e.target.value)}
                required
                disabled={discussions.pageState === AppState.LOADING}
                value={post}
              />
            </div>

            <div className="col-1">
              <svg
                style={{ cursor: "pointer" }}
                onClick={submit}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="rgba(218, 55, 50, 1)"
                className="bi bi-send ms-3"
                viewBox="0 0 16 16"
              >
                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
              </svg>{" "}
            </div>
          </div>{" "}
        </form>
      </div>

      <DiscussionReplies
        setDiscussion={setDiscussion}
        discussion={discussion!}
        contentID={contentID}
      />
    </>
  );
};

export default Discussions;
