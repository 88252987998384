import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../redux/states";
import { BaseState, StateKeys } from "../../../redux/states/main.state";
import Main from "../../../redux/actions/main.action";
import ApiEndpoint from "../../../services/api.endpoint.service";
import {
  AppState,
  CheckContentProviderIdExist,
} from "../../../services/app.service";
import { Col } from "react-bootstrap";
import Loader from "../../../components/Loader";
import {
  AnnouncementResponse,
  EventResponse,
} from "../../../services/api.response.service";
import AnnouncementCard from "../../../components/AnnouncementCard";
import { Link } from "react-router-dom";
import EventCard from "../../../components/EventCard";
import NoData from "../NoData";
import { RouteEndpoints } from "../../../services/route.service";
import EventDetailsOffCanvas from "../../../components/EventDetailsModal";
import AnnouncementDetailsOffCanvas from "../../../components/AnnouncementDetailsModal";
import Languages from "../../../components/Languages";

function Home() {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);
  const announcements: BaseState<Array<AnnouncementResponse>> =
    state[StateKeys.ANNOUNCEMENTS];
  const events: BaseState<Array<EventResponse>> = state[StateKeys.EVENTS];

  const [showAnnouncementDetails, setShowAnnouncementDetails] =
    useState<AnnouncementResponse>();
  const [showEventDetails, setShowEventDetails] = useState<EventResponse>();

  useEffect(() => {
    if (announcements.pageState !== AppState.LOADED) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.ANNOUNCEMENTS,
          apiEndpoint: ApiEndpoint.ANNOUNCEMENTS,
          previousPage: 0,
          storeKey: StateKeys.ANNOUNCEMENTS,
          params: CheckContentProviderIdExist()
            ? { content_provider_id: process.env.REACT_APP_CONTENT_PROVIDER_ID }
            : {},
        })
      );
    }

    if (events.pageState !== AppState.LOADED) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.EVENTS,
          apiEndpoint: ApiEndpoint.EVENTS,
          previousPage: 0,
          storeKey: StateKeys.EVENTS,
          params: CheckContentProviderIdExist()
            ? { content_provider_id: process.env.REACT_APP_CONTENT_PROVIDER_ID }
            : {},
        })
      );
    }
  }, []);

  return (
    <>
      <div className="content-page scroller-hide">
        <Col lg={12} className="row m-0 p-0">
          <Col sm={12} className="d-flex justify-content-between mt-1">
            <div className="header-title">
              <h4 className="card-title">
                {" "}
                <Languages langKey="announcement" />
              </h4>
            </div>
            {announcements.data.length > 4 &&
              announcements.pageState === AppState.LOADED && (
                <div className="card-header-toolbar d-flex align-items-center">
                  <Link to={RouteEndpoints.ANNOUNCEMENTS}>
                    <Languages langKey="viewAll" />{" "}
                    <i className="las la-angle-right"></i>
                  </Link>
                </div>
              )}
          </Col>
          <Col sm={12} className="row m-0 p-0">
            {announcements.pageState === AppState.LOADING ? (
              <Loader noCard imageLoader />
            ) : announcements.data.length > 0 ? (
              announcements.data.slice(0, 4).map((item) => (
                <Col
                  key={item.id}
                  sm={12}
                  md={6}
                  className="mt-1"
                  onClick={() => setShowAnnouncementDetails(item)}
                >
                  <AnnouncementCard data={item} />
                </Col>
              ))
            ) : (
              <NoData title="noAnnouncement" />
            )}
          </Col>
        </Col>
        <Col lg={12} className="row m-0 p-0">
          <Col sm={12} className="d-flex justify-content-between mt-1">
            <div className="header-title">
              <h4 className="card-title">
                {" "}
                <Languages langKey="events" />
              </h4>
            </div>
            {events.data.length > 4 && events.pageState === AppState.LOADED && (
              <div className="card-header-toolbar d-flex align-items-center">
                <Link to={RouteEndpoints.EVENTS}>
                  {" "}
                  <Languages langKey="viewAll" />{" "}
                  <i className="las la-angle-right"></i>
                </Link>
              </div>
            )}
          </Col>
          <Col sm={12} className="row m-0 p-0">
            {events.pageState === AppState.LOADING ? (
              <Loader noCard imageLoader />
            ) : events.data.length > 0 ? (
              events.data.slice(0, 4).map((item) => (
                <Col
                  key={item.id}
                  sm={12}
                  md={6}
                  className="mt-1"
                  onClick={() => setShowEventDetails(item)}
                >
                  <EventCard data={item} />
                </Col>
              ))
            ) : (
              <NoData title="noEvents" />
            )}
          </Col>
        </Col>
        {showEventDetails && (
          <EventDetailsOffCanvas
            details={showEventDetails!}
            setShowEventDetails={setShowEventDetails}
          />
        )}
        {showAnnouncementDetails && (
          <AnnouncementDetailsOffCanvas
            details={showAnnouncementDetails!}
            setShowAnnouncementDetails={setShowAnnouncementDetails}
          />
        )}
      </div>
    </>
  );
}

export default Home;
