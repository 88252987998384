import React, {useEffect, useState} from 'react';
import {AppState} from "../services/app.service";
import {Alert} from "react-bootstrap";

interface props {
    message: string;
    state: AppState
    timer: number
}

function NotifyAlert({message,state,timer}:props) {
    const [show,setShow] = useState(true)
    useEffect(()=>{
        let time: NodeJS.Timeout
        if(timer > 0){
            time = setTimeout(()=>{
                setShow(false)
            },timer)
        }
        return () =>{
            if(time){
                clearTimeout(time)
            }
        }
    })
    const color = () => {
        let c;
        switch(state){
            case AppState.ERROR:
                c = 'danger'
                break
            case AppState.INFO:
                c = 'primary'
                break
            case AppState.SUCCESS:
                c = 'success'
                break
            case AppState.WARNING:
                c = 'warning'
                break
        }
        return c
    }
    const icon = () => {
        let c = 'las ';
        switch(state){
            case AppState.ERROR:
                c = c + 'la-exclamation-triangle'
                break
            case AppState.INFO:
                c = c + 'la-exclamation'
                break
            case AppState.SUCCESS:
                c = c + 'la-check-circle'
                break
            case AppState.WARNING:
                c = c + 'la-info-circle'
                break
        }
        return c
    }
    return (
        <Alert variant={`alert alert-solid alert-${color()}`} className="mb-3" show={show} role="alert" onClose={() => setShow(false)} dismissible>
            <span><i className={`text-white me-1 ${icon()}`} style={{fontSize:22}}/></span>
            <span>{message}</span>
        </Alert>
    );
}

export default NotifyAlert;