import React, {useState, useEffect} from "react";
import { RouteProps } from "react-router";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/images/favicon.png";
import student from "../assets/images/auth/student.svg";
import teacher from "../assets/images/auth/teacher.svg";
import business from "../assets/images/auth/business.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import {
  AppSettings, CheckAppLogoExist,
  CheckAuthSliderTextsExist,
  CheckContentProviderIdExist,
  getAppLogo,
  getAuthSliderTexts,
  getAppName,
  InfoLogger
} from "../services/app.service";
import AuthLanguageSwitch from "../components/AuthLanguageSwitch";
import Languages from "../components/Languages";

// install Swiper modules
SwiperCore.use([ Autoplay, Pagination]);

const signInPageStyle = (index: number) => {
  let style = {
    backgroundColor: index === 1 ? '#FCF4E4' : index === 2 ? '#E5EBF6' : '#F6EAE9'
  };
  // if (AppSettings().AUTH_BACKGROUND) {
  //   style = {
  //     background: AppSettings().AUTH_BACKGROUND,
  //     backgroundSize: "cover",
  //   };
  // }

  return style;
};

function AuthLayout({ children }: RouteProps) {

  const [activeSwiperIndex, setActiveSwiperIndex] = useState(0);

  return (
    <div>
    {/*<div className="wrapper">*/}
      <section className="sign-in-page" style={signInPageStyle(activeSwiperIndex)}>
        <Container className="p-0 m-0">
          <Row className="no-gutters vw-100 sign-in-row">
            <Col lg="8" md="6" className={`text-center ${CheckAppLogoExist() ? 'pt-5' : ''}`}>
              <div className="sign-in-detail text-white">
                <Link className={`sign-in-logo ${CheckAppLogoExist() ? 'mb-5' : ''}`} to="#">
                  {CheckAppLogoExist() && <Image src={getAppLogo()} className="img-fluid" alt="logo" />}
                </Link>
                <div className="sign-slider overflow-hidden d-flex" style={{alignItems: 'center', height: '100%'}}>
                  <Swiper
                  modules={[Pagination]}
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                    }}
                    onActiveIndexChange={(swiper: SwiperCore) => setActiveSwiperIndex(swiper.activeIndex)}
                    pagination={{ clickable: true }}
                    className="m-0 p-0"
                  >
                    {CheckAuthSliderTextsExist() ? <>
                      {getAuthSliderTexts().map((item,i)=><SwiperSlide key={i}>
                        {/*<Image src={login1} className="img-fluid mb-4" alt="logo"/>*/}
                        <h4 className="mb-1 text-white">
                          {" "}
                          {item.title}
                        </h4>
                        <p>
                          {item.desc}
                        </p>
                      </SwiperSlide>)}
                    </>:<>
                      <SwiperSlide>
                        <Image src={student} className="img-fluid mb-4 auth-slider-img" alt="logo"/>
                        <h4 className="mb-1 auth-slider-header-text">
                          {" "}
                          <Languages langKey="eStudents" />
                        </h4>
                        <p className="text-dark text-center auth-slider-details-text">
                          <Languages langKey="eStudentsP" />
                        </p>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Image src={teacher} className="img-fluid mb-4 auth-slider-img"  alt="logo"/>
                        <h4 className="mb-1 auth-slider-header-text">
                          {" "}
                          <Languages langKey="eTeacher" />
                        </h4>
                        <p className="text-dark text-center auth-slider-details-text">
                          <Languages langKey="eTeacherP" />
                        </p>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Image src={business} className="img-fluid mb-4 auth-slider-img" alt="logo"/>
                        <h4 className="mb-1 auth-slider-header-text">
                          {" "}
                          <Languages langKey="eBusiness" />
                        </h4>
                        <p className="text-dark text-center auth-slider-details-text">
                          <Languages langKey="eBusinessP" />
                        </p>
                      </SwiperSlide>
                    </>}
                  </Swiper>
                </div>
              </div>
            </Col>
            <Col
              lg="4"
              md="6"
              className="bg-white pt-5 pt-5 pb-lg-0 pb-5 position-relative d-flex flex-column"
            >
              <AuthLanguageSwitch />

              {children}

              {/* <div className="w-100 d-flex justify-content-center mt-auto pb-4">
              <Link to="#" className="text-dark" style={{fontWeight: 'bold'}}>
                  <Image src={CheckAppLogoExist() ? getAppLogo() : logo} style={{width: 30}} alt="logo" /> {getAppName()}
                </Link>
              </div> */}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default AuthLayout;
