import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../redux/states";
import { StateKeys } from "../../../redux/states/main.state";
import {
  AppPageScrollDownLoadMore,
  AppState,
  CheckContentProviderIdExist,
  getContentProviderId,
} from "../../../services/app.service";
import Main from "../../../redux/actions/main.action";
import ApiEndpoint from "../../../services/api.endpoint.service";
import Loader from "../../../components/Loader";
import {
  ClassResponse,
  ContentProviderResponse,
} from "../../../services/api.response.service";
import ClassesCardVList from "../../../components/ClassesCardVList";
import { RouteEndpoints } from "../../../services/route.service";
import NoData from "../NoData";
import Languages from "../../../components/Languages";

function ContentProviderClasses() {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);

  const details = state[StateKeys.CONTENT_PROVIDER_DETAILS];
  const classes = state[StateKeys.CONTENT_PROVIDER_CLASSES];
  const contentProviderId = getContentProviderId();

  useEffect(() => {
    if (
      // details.pageState !== AppState.LOADED ||
      // (details.pageState === AppState.LOADED &&
      //   String((details.data as ContentProviderResponse).id) !== contentProviderId)
        true
    ) {
      dispatch(
        Main.getPageObjectData({
          stateKey: StateKeys.CONTENT_PROVIDER_DETAILS,
          apiEndpoint: ApiEndpoint.ACTIVE_CONTENT_PROVIDERS(Number(contentProviderId)),
        })
      );
      dispatch(
        Main.postPaginatedPageDataSuccess({
          stateKey: StateKeys.CONTENT_PROVIDER_CLASSES,
          data: [],
          clear: true,
        })
      );
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CONTENT_PROVIDER_CLASSES,
          apiEndpoint: ApiEndpoint.CONTENT_PROVIDERS_CLASSES(Number(contentProviderId), getContentProviderId()),
          previousPage: 0,
        })
      );
    }
  }, [contentProviderId]);

  const loadMore = () => {
    // @ts-ignore
    if (
      window.innerHeight + document.documentElement.scrollTop === // @ts-ignore
        document.scrollingElement.scrollHeight &&
      classes.hasMore &&
      (classes.pageState !== AppState.LOADING || // @ts-ignore
        classes.pageState !== AppState.LOADING_MORE)
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CONTENT_PROVIDER_CLASSES,
          apiEndpoint: ApiEndpoint.CONTENT_PROVIDERS_CLASSES(Number(contentProviderId), getContentProviderId()),
          previousPage: classes.currentPage!,
        })
      );
    }
  };

  return (
    <div
      className="content-page scroller-hide"
      onScroll={(e) => AppPageScrollDownLoadMore(e, loadMore)}
    >
      <Row className="content-card pe-4 mt-4*">
        {(details.pageState === AppState.LOADED ||
          details.pageState === AppState.LOADING_MORE) && (
          <>
            {details.data ? (
              <>
                {/*<Col sm={12}>
                  <Card>
                    <Card.Body>
                      <ul className="notification-list m-0 p-0">
                        <li className="d-flex align-items-center justify-content-between">
                          <div className="user-img img-fluid">
                            <img
                              src={
                                (details.data as ContentProviderResponse)
                                  .logo_url
                              }
                              alt="story-img"
                              className="rounded avatar-40"
                            />
                          </div>
                          <div className="w-100">
                            <div className="d-flex justify-content-between">
                              <div className=" ms-3">
                                <h6>
                                  {
                                    (details.data as ContentProviderResponse)
                                      .name
                                  }
                                </h6>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={12} className="mb-2">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col md={12}>
                          <h4>Details</h4>
                          <hr />
                          <Row>
                            <Col md={2}>
                              <h6>Description</h6>
                            </Col>
                            <Col md={10}>
                              <p className="mb-0">
                                {
                                  (details.data as ContentProviderResponse)
                                    .description
                                }
                              </p>
                            </Col>
                            <Col md={2}>
                              <h6>Country</h6>
                            </Col>
                            <Col md={10}>
                              <p className="mb-0">
                                {
                                  (details.data as ContentProviderResponse)
                                    .country
                                }
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>*/}
                <ClassesCardVList
                  data={classes.data as Array<ClassResponse>}
                  state={classes.pageState}
                  title="classes"
                />
              </>
            ) : (
              <NoData
                title="noContentProviderFound"
                link={RouteEndpoints.CONTENT_PROVIDERS}
                btnText="backToContent"
              />
            )}
          </>
        )}
        <Col md={12}>
          {(classes.hasMore &&
            !(classes.pageState === AppState.LOADING ||
              classes.pageState === AppState.LOADING_MORE)) && (
            <div className={`w-100 text-center shadow-xss rounded-xxl border-0 px-3`}>
              <button
                className="text-decoration-none btn btn-primary"
                onClick={() => loadMore()}
              >
                <Languages langKey="loadMore" />
              </button>
            </div>
          )}
        </Col>
        <Col md={12}>
          {(classes.pageState === AppState.LOADING_MORE ||
            classes.pageState === AppState.LOADING) && (
            <Loader noCard imageLoader />
          )}
        </Col>
        {(details.pageState === AppState.ERROR_404 ||
          !CheckContentProviderIdExist()) && (
          <NoData
            title="cpNotFound"
            // link={RouteEndpoints.CONTENT_PROVIDERS}
            // btnText="Back to Content Providers"
          />
        )}
      </Row>
    </div>
  );
}

export default ContentProviderClasses;
