import React, {ReactNode} from 'react'

interface Props {
    className?: string;
    children: ReactNode
}

const Card = (props:Props) => <div className={`card ${props.className ? props.className : ''}`}> {props.children} </div>
Card.Header = (props:Props) => <div className={`card-header d-flex justify-content-between ${props.className ? props.className : ''}`}> {props.children} </div>
Card.Body = (props:Props) => <div className={`card-body ${props.className ? props.className :''}`}> {props.children} </div>
Card.Footer = (props:Props) => <div className="card-footer"> {props.children} </div>
Card.Title = (props:Props) => <div className={`header-title ${props.className ? props.className : ''}`}> {props.children} </div>
Card.Action = (props:Props) => <div className={`header-action ${props.className ? props.className : ''}`}> {props.children} </div>

export default Card;