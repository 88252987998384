import React, {useEffect} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../redux/states";
import {RouteComponentProps} from "react-router";
import Main from "../../redux/actions/main.action";
import {StateKeys} from "../../redux/states/main.state";
import ApiEndpoint from "../../services/api.endpoint.service";
import {AppPageScrollDownLoadMore, AppState} from "../../services/app.service";
import PageNotFound from "./PageNotFound";
import {ClassResponse} from "../../services/api.response.service";
import ClassesCardVList from "../../components/ClassesCardVList";
import ClassesCardVList2 from "../../components/ClassesCardVList2";
import Languages from "../../components/Languages";

function HomeViewMore({location}:RouteComponentProps){
    const key = location.pathname.replace('/hh/','')
    const dispatch = useDispatch()
    const state = useSelector(((state: IRootState) => state.main))
    const pageData = state[key]
    const validKey = (key:string) => key === StateKeys.RECENT || key === StateKeys.TRENDING || StateKeys.ACTIVE ||
        StateKeys.FELLOWS || StateKeys.PRIME || StateKeys.BUSINESS || StateKeys.BOOK || StateKeys["TEST-PREP"]

    useEffect(()=>{
        if(validKey(key) && pageData.pageState !== AppState.LOADED ){
            dispatch(Main.getPaginatedPageData({stateKey: key, apiEndpoint: ApiEndpoint.HOME_PAGE_CATEGORY_CLASSES(key), previousPage: 0}))
        }
    }, [key])

    if(!validKey(key)){
       return <PageNotFound/>
    }

    const loadMore = () => {
        // @ts-ignore
        if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight && pageData.hasMore && (pageData.pageState !== AppState.LOADING || pageData.pageState !== AppState.LOADING_MORE)) {
            dispatch(Main.getPaginatedPageData({stateKey: key, apiEndpoint: ApiEndpoint.HOME_PAGE_CATEGORY_CLASSES(key), previousPage: pageData.currentPage!}))
        }
    }

    return (
            <div className="content-page scroller-hide" onScroll={(e)=>AppPageScrollDownLoadMore(e,loadMore)}>
                <Row>
                   <ClassesCardVList2 data={(pageData.data as Array<ClassResponse>)} state={pageData.pageState} title={key}/>
                  <Col md={12}>
                    {(pageData.hasMore &&
                      !(pageData.pageState === AppState.LOADING ||
                        pageData.pageState === AppState.LOADING_MORE)) && (
                      <div className={`w-100 text-center shadow-xss rounded-xxl border-0 px-3`}>
                        <button
                          className="text-decoration-none btn btn-primary"
                          onClick={() => loadMore()}
                        >
                          <Languages langKey="loadMore" />
                        </button>
                      </div>
                    )}
                  </Col>
                </Row>
            </div>
    )
}

export default HomeViewMore
