import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { TestResponse } from "../services/api.response.service";
import { Link } from "react-router-dom";
import { RouteEndpoints } from "../services/route.service";
import NotFound from "./NotFound/NotFound";

interface props {
  tests: Array<TestResponse>;
  setShowTestDetails: (test: TestResponse) => void;
  getTestResults: (test: TestResponse) => void;
}

const TestsCardList = ({
  tests,
}: // setShowTestDetails,
// getTestResults,
props) => {
  return (
    <>
      <Row
        className="overflow-scroll"
        // style={{
        //   height: "68em",
        // }}
      >
        {tests?.length > 0 ? (
          tests?.map((test: any) => (
            <Col key={test.id} sm={6} md={4} xl={4} className="mt-3">
              <Card
                className="test-card"
                as={Link}
                to={RouteEndpoints.CLASS_COURSES_TEST(test.id)}
                // onClick={() => {
                //   setShowTestDetails(test);
                //   getTestResults(test);
                // }}
              >
                <Card.Body>
                  <h6 className="text-truncate">{test.name}</h6>
                  {/* <p className="text-truncate mt-2 mb-0 pb-2">
                  {test.description}
                </p>
                <p className="text-truncate mb-0 pb-0">{test.instructions}</p> */}

                  {/*<div className="mt-2 d-flex justify-content-between">*/}
                  {/*  <small*/}
                  {/*    className="d-flex gap-1 align-items-center"*/}
                  {/*    data-toggle="tooltip"*/}
                  {/*    data-placement="top"*/}
                  {/*    title="Test Duration"*/}
                  {/*  >*/}
                  {/*    <i*/}
                  {/*      className="las la-stopwatch"*/}
                  {/*      style={{ fontSize: 18 }}*/}
                  {/*    ></i>{" "}*/}
                  {/*    <span className="text-primary">{test.duration}m</span>*/}
                  {/*  </small>*/}
                  {/*  {test.is_test_available && (*/}
                  {/*    <small*/}
                  {/*      className="text-success ms-auto"*/}
                  {/*      data-toggle="tooltip"*/}
                  {/*      data-placement="top"*/}
                  {/*      title="Test Available"*/}
                  {/*    >*/}
                  {/*      <i*/}
                  {/*        className="las la-check-square"*/}
                  {/*        style={{ fontSize: 18 }}*/}
                  {/*      ></i>{" "}*/}
                  {/*    </small>*/}
                  {/*  )}*/}
                  {/*</div>*/}
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <NotFound
              title="No Test here"
              subTitle=""
              icon={
                <svg
                  width="61"
                  height="60"
                  viewBox="0 0 61 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5 42.5L30.5 55L55.5 42.5"
                    stroke="#DA3732"
                    stroke-opacity="0.6"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.5 30L30.5 42.5L55.5 30"
                    stroke="#DA3732"
                    stroke-opacity="0.6"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M30.5 5L5.5 17.5L30.5 30L55.5 17.5L30.5 5Z"
                    stroke="#DA3732"
                    stroke-opacity="0.6"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
            />
          </div>
        )}
      </Row>
    </>
  );
};

export default TestsCardList;
