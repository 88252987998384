import React, { useEffect, useState } from "react";
import { Dropdown, Navbar } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";

import { RouteEndpoints } from "../services/route.service";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../redux/states";
import {
  CheckContentProviderIdExist,
  getLanguageByDefault,
  LANGUAGES,
} from "../services/app.service";
import Actions from "../redux/actions";
import SignInModal from "./UnAuthorizedPage/SignInModal";

interface sideBarProps {
  triggerSidebar?: () => void;
}

function Header({ triggerSidebar }: sideBarProps) {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state);

  const currentUser = state.setting.user!;
  const language = state.setting.language;

  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search).get("q");
  const checkUrl = location.pathname.includes(
    RouteEndpoints.SEARCHED_RESULT("")
  );
  const [search, setSearch] = useState<string>(checkUrl ? query ?? "" : "");
  const getLanguage = getLanguageByDefault();

  const [show, setShow] = useState(false);

  const hideModal = () => setShow(false);

  useEffect(() => {
    if (window.innerWidth > 2500) {
      miniSidebar();
    }
  }, []);

  const miniSidebar = () => {
    document.body.classList.toggle("sidebar-main");

    // const cl = document.querySelector(".container");
  };

  const searchForData = (e: any) => {
    e.preventDefault();

    if (!currentUser.ecampus_id) {
      setShow(true);
      return;
    }

    if (checkUrl) {
      return history.replace(RouteEndpoints.SEARCHED_RESULT(`?q=${search}`));
    }
    return history.push(RouteEndpoints.SEARCHED_RESULT(`?q=${search}`));
  };

  const handleLang = (lang: LANGUAGES) => {
    dispatch(Actions.setting.setLanguage(lang));
  };

  return (
    <>
      <div className="iq-top-navbar">
        <div className="iq-navbar-custom">
          <Navbar
            expand="lg"
            variant="light"
            className="p-0 row justify-content-between* gap-0"
          >
            {/* <div className="iq-navbar-logo d-flex justify-content-between">
              <Link to="/">
                <Image
                  src={CheckAppLogoExist() ? getAppLogo() : logo}
                  className="img-fluid"
                  alt="app logo"
                />
              </Link>
              <div className="iq-menu-bt align-self-center">
                <div className="wrapper-menu" onClick={miniSidebar}>
                  <div className="main-circle">
                    <i className="ri-menu-line" />
                  </div>
                </div>
              </div>
            </div> */}

            <div className="iq-menu-bt* d-flex gap-2 col-12 col-sm-1 d-lg-none">
              <svg
                width="14"
                height="25"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="6.47079"
                  height="6.47079"
                  rx="0.980121"
                  transform="matrix(0.733565 -0.679619 -0.679619 -0.733565 7.05664 27.4434)"
                  fill="#EA983F"
                />
                <rect
                  width="13.3897"
                  height="7.76503"
                  rx="0.980121"
                  transform="matrix(0.987079 -0.160233 -0.160233 -0.987079 1.24414 19.0869)"
                  fill="#DF6438"
                />
                <rect
                  width="13.302"
                  height="8.88115"
                  rx="0.980121"
                  transform="matrix(1 0 0 -1 3.59961 8.88086)"
                  fill="#BB1C17"
                />
              </svg>
              <div className="wrapper-menu" onClick={triggerSidebar}>
                <div className="main-circle">
                  <i className="ri-menu-line" />
                </div>
              </div>
            </div>

            {!CheckContentProviderIdExist() && (
              <div className="iq-search-bar device-search col-12 col-sm-8 col-lg-9">
                <form className="searchbox w-100" onSubmit={searchForData}>
                  {search.length > 0 && (
                    <button className="search-link" type="submit">
                      <i className="ri-search-line text-primary" />
                    </button>
                  )}
                  <div className="search-icon">
                    <i className="ri-search-line text-secondary" />
                  </div>

                  <input
                    type="text"
                    className="text search-input bg-white"
                    placeholder={getLanguage.searchHere}
                    defaultValue={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </form>
              </div>
            )}

            <Dropdown className="col-12 col-sm-2 col-lg-2">
              <Dropdown.Toggle
                id="dropdown-basic"
                className="d-flex justify-content-center align-items-center gap-1 border border-0 text-dark"
                style={{
                  background: "none",
                  color: "none !important",
                }}
              >
                {/* <i className="las la-globe" style={{ fontSize: "20px" }} /> */}

                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.41 9.52233C21.41 9.52233 21.41 9.52233 21.41 9.47233C20.7054 7.54855 19.4269 5.88761 17.7475 4.71419C16.0681 3.54077 14.0688 2.9115 12.02 2.9115C9.97126 2.9115 7.97195 3.54077 6.29252 4.71419C4.6131 5.88761 3.33461 7.54855 2.63001 9.47233C2.63001 9.47233 2.63001 9.47233 2.63001 9.52233C1.84314 11.6932 1.84314 14.0714 2.63001 16.2423C2.63001 16.2423 2.63001 16.2423 2.63001 16.2923C3.33461 18.2161 4.6131 19.877 6.29252 21.0505C7.97195 22.2239 9.97126 22.8532 12.02 22.8532C14.0688 22.8532 16.0681 22.2239 17.7475 21.0505C19.4269 19.877 20.7054 18.2161 21.41 16.2923C21.41 16.2923 21.41 16.2923 21.41 16.2423C22.1969 14.0714 22.1969 11.6932 21.41 9.52233ZM4.26001 14.8823C3.91324 13.5715 3.91324 12.1931 4.26001 10.8823H6.12001C5.96004 12.2109 5.96004 13.5538 6.12001 14.8823H4.26001ZM5.08001 16.8823H6.48001C6.71473 17.7742 7.05022 18.6364 7.48001 19.4523C6.49931 18.7843 5.67951 17.9064 5.08001 16.8823ZM6.48001 8.88233H5.08001C5.67088 7.86141 6.4802 6.98379 7.45001 6.31233C7.03057 7.12957 6.70515 7.99175 6.48001 8.88233ZM11 20.5823C9.77178 19.681 8.90916 18.3676 8.57001 16.8823H11V20.5823ZM11 14.8823H8.14001C7.9534 13.5555 7.9534 12.2091 8.14001 10.8823H11V14.8823ZM11 8.88233H8.57001C8.90916 7.3971 9.77178 6.08365 11 5.18233V8.88233ZM18.92 8.88233H17.52C17.2853 7.99049 16.9498 7.12827 16.52 6.31233C17.5007 6.9804 18.3205 7.85827 18.92 8.88233ZM13 5.18233C14.2282 6.08365 15.0909 7.3971 15.43 8.88233H13V5.18233ZM13 20.5823V16.8823H15.43C15.0909 18.3676 14.2282 19.681 13 20.5823ZM15.86 14.8823H13V10.8823H15.86C16.0466 12.2091 16.0466 13.5555 15.86 14.8823ZM16.55 19.4523C16.9798 18.6364 17.3153 17.7742 17.55 16.8823H18.95C18.3505 17.9064 17.5307 18.7843 16.55 19.4523ZM19.74 14.8823H17.88C17.9613 14.2188 18.0014 13.5508 18 12.8823C18.0011 12.2138 17.961 11.5459 17.88 10.8823H19.74C20.0868 12.1931 20.0868 13.5715 19.74 14.8823Z"
                    fill="#505050"
                  />
                </svg>
                {language === LANGUAGES.FR ? "FR" : "ENG"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => handleLang(LANGUAGES.EN)}
                  className="lang-nav"
                  style={{ lineHeight: "20px" }}
                >
                  {getLanguage.english}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleLang(LANGUAGES.FR)}
                  className="lang-nav"
                  style={{ lineHeight: "20px" }}
                >
                  {getLanguage.french}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Navbar>

          <SignInModal hideModal={hideModal} open={show} />
        </div>
      </div>
    </>
  );
}

export default Header;
