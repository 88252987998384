import React from "react";
import { Redirect, Route, Switch } from "react-router";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Container } from "react-bootstrap";
import { RouteEndpoints, Routes } from "../services/route.service";

function DashboardLayout() {
  const triggerSidebar = () => {
    // @ts-ignore
    const sidebar = document.querySelector(".iq-sidebar");

    // @ts-ignore
    sidebar.classList.toggle("show");
  };

  return (
    <>
      <div className="position-relative">
        <div className="wrapper">
          <Sidebar triggerSidebar={triggerSidebar} />

          <div id="content-page" style={{ overflow: "hidden" }}>
            <TransitionGroup>
              <CSSTransition classNames="fadeUp" timeout={300}>
                <Container className="dashboard-layout overflow-hidden lg">
                  <Header triggerSidebar={triggerSidebar} />
                  <div
                    style={{
                      overflowY: "scroll",
                      overflowX: "hidden",
                      height: "100%",
                      maxHeight: "90vh",
                    }}
                  >
                    <Switch>
                      {Routes.map((route, i) =>
                        route.private ? (
                          <Route
                            key={i}
                            exact={route.exact}
                            path={route.path}
                            render={(props) => <route.component {...props} />}
                          />
                        ) : (
                          !route.private &&
                          route.path === RouteEndpoints.UNAUTH_HOME && (
                            <Route
                              key={i}
                              exact={route.exact}
                              path={route.path}
                              render={(props) => <route.component {...props} />}
                            />
                          )
                        )
                      )}
                      <Redirect to={{ pathname: RouteEndpoints.HOME }} />
                    </Switch>
                  </div>
                </Container>
              </CSSTransition>
            </TransitionGroup>
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardLayout;
