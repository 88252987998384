import rootState from "../states";
import { types } from "../actions/setting.action";
import constants from "../../utils/constants";
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = rootState.setting, { type, payload }: any) {
  switch (type) {
    case types.USER:
      // localStorage.setItem(constants.USER, JSON.stringify(payload))
      return { ...state, user: payload };
    case types.HEADER_OPEN:
      return { ...state, header: { ...state.header, open: payload } };
    case types.HEADER_ACTIVE:
      return { ...state, header: { ...state.header, active: payload } };
    case types.HEADER_NOTIFICATION:
      return { ...state, header: { ...state.header, notification: payload } };
    case types.HEADER_SHOW_RIGHT_CHAT:
      return { ...state, header: { ...state.header, showRightChat: payload } };
    case types.ADD_LOADER_ID:
      return { ...state, loaderIds: [...state.loaderIds, payload] };
    case types.REMOVE_LOADER_ID:
      return {
        ...state,
        loaderIds: [...state.loaderIds.filter((e) => e !== payload)],
      };
    case types.RECAPTCHA_SITE_KEY:
      return { ...state, keys: { ...state.keys, recaptchaSiteKey: payload } };
    case types.LANGUAGE:
      localStorage.setItem(constants.LANG, payload)
      return { ...state, language: payload };
    case types.DARK_MODE:
      if (payload) {
        // localStorage.setItem(constants.DARK, payload)
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
        localStorage.removeItem(constants.DARK);
      }
      return { ...state, scheme: payload };
    case types.SIDEBAR_SHOW:
      if (payload) {
        // localStorage.setItem(constants.SIDEBAR, payload)
        document.body.classList.toggle("sidebar-main");
      } else {
        document.body.classList.remove("dark");
        // localStorage.removeItem(constants.SIDEBAR)
      }
      document.body.classList.toggle("sidebar-main");
      return { ...state, sidebar: payload };
    default:
      return { ...state };
  }
}
