import axios, { AxiosRequestConfig } from "axios";
import constants from "../utils/constants";
import {
  ForgotPasswordRequest,
  RegisterRequest,
  SignInRequest,
  SignUpRequest,
  UpdateInterestsRequest,
  UpdateUserRequest,
} from "./api.request.service";
import ApiEndpoint from "./api.endpoint.service";
import { ErrorLogger, GetUserToken, InfoLogger } from "./app.service";
export const baseURL = process.env.REACT_APP_API_ENDPOINT;

export const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + GetUserToken(),
  },
});

api.interceptors.response.use(
  (response) => {
    InfoLogger("response", response);
    return response;
  },
  (error) => {
    ErrorLogger("error", error.response);
    if (error && error.response && error.response.status === 401) {
      if(GetUserToken()){
        localStorage.removeItem(constants.TOKEN);
        localStorage.setItem("to.path", window.location.pathname);
        window.location.replace("/login");
      }
    }
    return Promise.reject(error);
  }
);

const Api = {
  //Auth
  signIn: (data: SignInRequest) => api.post(ApiEndpoint.AUTH_SIGN_IN, data),
  signUp: (data: SignUpRequest) => api.post(ApiEndpoint.AUTH_SIGN_UP, data),
  register: (data: RegisterRequest) =>
    api.post(ApiEndpoint.AUTH_REGISTER, data),
  passwordForgot: (data: ForgotPasswordRequest) =>
    api.post(ApiEndpoint.AUTH_FORGOT_PASSWORD, data),
  deleteAccount: () => api.post(ApiEndpoint.DELETE_USER),

  //User
  getUser: () => api.get(ApiEndpoint.USER),
  updateUser: (data: UpdateUserRequest) =>
    api.post(ApiEndpoint.UPDATE_USER, data),
  updateInterests: (data: UpdateInterestsRequest) =>
    api.post(ApiEndpoint.UPDATE_INTERESTS, data),
  getUserInterests: () => api.get(ApiEndpoint.USER_INTERESTS),
  getUserRecentTopics: (page: number) =>
    api.get(ApiEndpoint.USER_RECENT_TOPICS, { params: { page } }),
  getUserSubscriptions: (page: number) =>
    api.get(ApiEndpoint.USER_SUBSCRIPTIONS, { params: { page } }),
  getUserPaidSubscriptions: (page: number) =>
    api.get(ApiEndpoint.USER_PAID_SUBSCRIPTIONS, { params: { page } }),
  getUserPoints: (page: number) =>
    api.get(ApiEndpoint.USER_POINTS, { params: { page } }),
  getUserPointsDetails: (page: number) =>
    api.get(ApiEndpoint.USER_POINTS_DETAILS, { params: { page } }),

  //Content
  getAllActiveContentProviders: (search_word: string) =>
    api.get(ApiEndpoint.ALL_ACTIVE_CONTENT_PROVIDERS, {
      params: { search_word },
    }),
  getActiveContentProvider: (id: number) =>
    api.get(ApiEndpoint.ACTIVE_CONTENT_PROVIDERS(id)),
  getContentProviderClasses: (id: number) =>
    api.get(ApiEndpoint.CONTENT_PROVIDERS_CLASSES(id, null)),

  //Classes
  // getActiveClasses: (search_word: string) =>
  //   api.get(ApiEndpoint.ACTIVE_CLASSES, { params: { search_word } }),
  getActiveClasses: (search_word: string) =>
    api.get(ApiEndpoint.ACTIVE_CLASSES(search_word)),
  getActiveClass: (id: number) => api.get(ApiEndpoint.ACTIVE_CLASS(id)),
  getHomePageCategoryClasses: (category: string) =>
    api.get(ApiEndpoint.HOME_PAGE_CATEGORY_CLASSES(category)),
  getCategoryClasses: (id: number) => api.get(ApiEndpoint.CATEGORY_CLASSES(id)),
  subscribeClass: (id: number) => api.post(ApiEndpoint.SUBSCRIBE_CLASS(id), {}),
  unsubscribeClass: (id: number) =>
    api.post(ApiEndpoint.UNSUBSCRIBE_CLASS(id), {}),

  //Content
  getClassCourses: (id: number) => api.get(ApiEndpoint.CLASS_COURSES(id)),
  getCourseTopics: (id: number) => api.get(ApiEndpoint.COURSE_TOPICS(id)),
  getClassContent: (id: number) => api.get(ApiEndpoint.COURSE_CONTENT(id)),
  getClassContentDiscussion: (id: number) =>
    api.get(ApiEndpoint.CLASS_CONTENT_DISCUSSION(id)),

  //Test
  getClassTest: (id: number) => api.get(ApiEndpoint.CLASS_TESTS(id)),

  //Engagements
  getAnnouncements: () => api.get(ApiEndpoint.ANNOUNCEMENTS),
  getEvents: () => api.get(ApiEndpoint.EVENTS),

  //Helpers
  getAllCategories: () => api.get(ApiEndpoint.ALL_CATEGORIES),
  getAllInterests: () => api.get(ApiEndpoint.ALL_INTERESTS),
  getInterestsClasses: (id: number) =>
    api.get(ApiEndpoint.INTEREST_CLASSES(id)),

  //Search
  //   search: (search_text: string) =>
  //     api.get(ApiEndpoint.GENERAL_SEARCH, { params: { search_text } }),
  //   accessContent: (content_id: number, search_text: string) =>
  //     api.get(ApiEndpoint.GENERAL_SEARCH, {
  //       params: { content_id, search_text },
  //     }),

  //other
  getPageData: (url: string, params: Object) =>
    api.get(url, { params: { ...params } }),
  postPageData: (url: string, data: any, config?: AxiosRequestConfig) =>
    api.post(url, data, config),
  getPaginatedPageData: (url: string, params: Object) =>
    api.get(url, { params: { ...params } }),
  postPaginatedPageData: (url: string, data: any, params: Object) =>
    api.post(url, data, { params: { ...params } }),
};

export default Api;
