import React, { FormEvent, useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import {
  AppState,
  CheckContentProviderIdExist,
  getKey,
} from "../../services/app.service";
import Actions from "../../redux/actions";
import constants from "../../utils/constants";
import { remoteConfig } from "../../services/firebase.service";
import Languages from "../Languages";
import NotifyAlert from "../NotifyAlert";
import { Link } from "react-router-dom";
import { RouteEndpoints } from "../../services/route.service";
import OAuth from "../../pages/auth/OAuth";

interface props {
  open: boolean;
  hideModal: () => void;
}

const disableCaptcha =
  process.env.REACT_APP_ALLOW_CAPTCHA &&
  process.env.REACT_APP_ALLOW_CAPTCHA === "false";

const SignInModal = ({ open, hideModal }: props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(disableCaptcha ? "no" : "");
  const [retries, setRetries] = useState(3);
  const dispatch = useDispatch();
  const { state, message } = useSelector(
    (state: IRootState) => state.auth.signIn
  );
  const { recaptchaSiteKey } = useSelector(
    (state: IRootState) => state.setting.keys
  );

  const [showPassword, setShowPassword] = useState(false);
  const [loadCaptcha, setLoadCaptcha] = useState(-3);

  const recaptchaSiteKey1 =
    remoteConfig.getValue(getKey(constants.RECAPTCHA_SITE_KEY)).asString() ??
    "";

  useEffect(() => {
    if (!recaptchaSiteKey && recaptchaSiteKey1) {
      dispatch(Actions.setting.setRecaptchaSiteKey(recaptchaSiteKey1));
    }
  }, [recaptchaSiteKey1]);

  useEffect(() => {
    try {
      loadRC();
    } catch (e) {
      // console.error("captcha", e);
      setLoadCaptcha(loadCaptcha + 1);
    }
  }, [recaptchaSiteKey, loadCaptcha]);

  useEffect(() => {
    if (state === AppState.RETRY && retries > 0) {
      setRetries((prevState) => prevState - 1);
      dispatch(Actions.auth.signIn({ username, password, token }));
    }
  }, [state]);

  const loadRC = () => {
    if (loadCaptcha < 0 && recaptchaSiteKey.length > 0 && !disableCaptcha) {
      // @ts-ignore
      loadReCaptcha(recaptchaSiteKey, () => {
        setLoadCaptcha(1);
      });
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    loadRC();
    e.preventDefault();
    dispatch(
      Actions.auth.signIn({ username, password, token: token ?? "yes" })
    );
  };

  return (
    <>
      {" "}
      <Modal centered show={open} onHide={hideModal}>
        <Modal.Header closeButton />
        <Modal.Body className="text-center">
          <div className="sign-in-from*">
            {/*<h1 className="mb-0">*/}
            {/*  <Languages langKey="signIn" />*/}
            {/*</h1>*/}

            {(state === AppState.ERROR || state === AppState.ERROR_INPUT) && (
              <NotifyAlert
                message={message}
                state={AppState.ERROR}
                timer={10000}
              />
            )}
            <Form className="mt-4" onSubmit={handleSubmit}>
            {/*  <Form.Group className="form-group">*/}
            {/*    <Form.Label className="h6">*/}
            {/*      <Languages langKey="username" /> /{" "}*/}
            {/*      <Languages langKey="email" />*/}
            {/*    </Form.Label>*/}
            {/*    <Form.Group className="input-group">*/}
            {/*      <span className="input-group-text" id="basic-addon1">*/}
            {/*        <i className="las la-user" />*/}
            {/*      </span>*/}
            {/*      <Form.Control*/}
            {/*        className={`mb-0 ${*/}
            {/*          state === AppState.ERROR_INPUT && "is-invalid"*/}
            {/*        }`}*/}
            {/*        required*/}
            {/*        onChange={(event) => setUsername(event.target.value)}*/}
            {/*      />*/}
            {/*    </Form.Group>*/}
            {/*  </Form.Group>*/}
            {/*  <Form.Group className="form-group">*/}
            {/*    <Form.Label className="h6">*/}
            {/*      <Languages langKey="password" />*/}
            {/*    </Form.Label>*/}

            {/*    <Form.Group className="input-group">*/}
            {/*      <span className="input-group-text">*/}
            {/*        <i className="las la-lock" />*/}
            {/*      </span>*/}
            {/*      <Form.Control*/}
            {/*        type={showPassword ? "text" : "password"}*/}
            {/*        className="mb-0 border-end-0"*/}
            {/*        required*/}
            {/*        onChange={(event) => setPassword(event.target.value)}*/}
            {/*      />*/}
            {/*      <span*/}
            {/*        className="input-group-text bg-transparent"*/}
            {/*        onClick={() => setShowPassword(!showPassword)}*/}
            {/*      >*/}
            {/*        <i*/}
            {/*          className={`las la-${showPassword ? "eye-slash" : "eye"}`}*/}
            {/*        />*/}
            {/*      </span>*/}
            {/*    </Form.Group>*/}
            {/*    <Link*/}
            {/*      to={RouteEndpoints.FORGOT_PASSWORD}*/}
            {/*      className="float-end pt-2"*/}
            {/*    >*/}
            {/*      <Languages langKey="forgotPassword" />?*/}
            {/*    </Link>*/}
            {/*  </Form.Group>*/}
            {/*  <div className="d-grid w-100 pt-3">*/}
            {/*    <Button variant="primary " type="submit" className="float-end*">*/}
            {/*      {state === AppState.LOADING && (*/}
            {/*        <Spinner*/}
            {/*          animation={"border"}*/}
            {/*          size={"sm"}*/}
            {/*          className="me-1"*/}
            {/*        />*/}
            {/*      )}*/}
            {/*      <Languages langKey="signIn" />{" "}*/}
            {/*    </Button>*/}
            {/*  </div>*/}
              <div className="sign-info d-flex flex-column gap-4">
                <span className="dark-color d-inline-block line-height-2">
                  <Languages langKey="noAccount" />?
                  <Link to={RouteEndpoints.SIGN_UP}>
                    {" "}
                    <Languages langKey="signUp" />
                  </Link>
                </span>

                {!CheckContentProviderIdExist() && <OAuth />}
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>{" "}
    </>
  );
};

export default SignInModal;
