import React from "react";
import { Col, Row } from "react-bootstrap";
import { CourseResponse } from "../services/api.response.service";
import CourseCardItem from "./CourseCardItem";
import NotFound from "./NotFound/NotFound";

interface props {
  courses: Array<CourseResponse>;
  setShowCourseDetails: (course: CourseResponse) => void;
  getCourseTopic: (course: CourseResponse) => void;
}

const CourseCardItems = ({
  courses,
  setShowCourseDetails,
  getCourseTopic,
}: props) => {
  return (
    <>
      <Row>
        {courses?.length > 0 ? (
          courses?.map((course: CourseResponse, index) => (
            <Col
              key={index}
              xs={12}
              sm={6}
              lg={4}
              className="mt-3"
              onClick={() => {
                getCourseTopic(course);
                setShowCourseDetails(course);
              }}
            >
              <CourseCardItem item={course} />
            </Col>
          ))
        ) : (
          <NotFound
            title="No Content here"
            subTitle=""
            icon={
              <svg
                width="61"
                height="60"
                viewBox="0 0 61 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 42.5L30.5 55L55.5 42.5"
                  stroke="#DA3732"
                  stroke-opacity="0.6"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.5 30L30.5 42.5L55.5 30"
                  stroke="#DA3732"
                  stroke-opacity="0.6"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M30.5 5L5.5 17.5L30.5 30L55.5 17.5L30.5 5Z"
                  stroke="#DA3732"
                  stroke-opacity="0.6"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          />
        )}
      </Row>
    </>
  );
};

export default CourseCardItems;
