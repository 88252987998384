import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Languages from "../../components/Languages";
import Loader from "../../components/Loader";
import Main from "../../redux/actions/main.action";
import { IRootState } from "../../redux/states";
import { StateKeys } from "../../redux/states/main.state";
import ApiEndpoint from "../../services/api.endpoint.service";
import { Point, PointClassResponse } from "../../services/api.response.service";
import {
  AppPageScrollDownLoadMore,
  AppState,
} from "../../services/app.service";
import { RouteEndpoints } from "../../services/route.service";
import NoData from "./NoData";

const Points = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state);
  const currentUser = state.setting.user!;

  const { user_points_class } = useSelector((state: IRootState) => state.main);

  const { pageState, data, currentPage, hasMore } = user_points_class;

  useEffect(() => {
    if (pageState !== AppState.LOADED && pageState !== AppState.LOADING) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.USER_POINTS_CLASS,
          apiEndpoint: ApiEndpoint.USER_POINTS_CLASS,
          previousPage: currentPage!,
        })
      );
    }
  }, []);

  const calculatePoint = (points: Array<Point>) => {
    let sum = { read: 0, practice: 0, watch: 0 };
    points.forEach((e, i) => {
      // @ts-ignore
      sum = {
        ...sum,
        [e.where.toLowerCase()]:
          // @ts-ignore
          Number(sum[e.where.toLowerCase()] ?? 0) + e.marks,
      };
    });
    let total = 0;
    Object.keys(sum).forEach((e: string) => {
      // @ts-ignore
      total += sum[e];
    });
    // console.log(sum)
    // setTotalpoints(totalPoints + total)
    return { ...sum, total };
  };

  const loadMore = () => {
    // @ts-ignore
    if (
      // @ts-ignore
      window.innerHeight + document.documentElement.scrollTop ===
        // @ts-ignore
        document.scrollingElement.scrollHeight &&
      hasMore && // @ts-ignore
      (pageState !== AppState.LOADING || pageState !== AppState.LOADING_MORE)
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.USER_POINTS_CLASS,
          apiEndpoint: ApiEndpoint.USER_POINTS_CLASS,
          previousPage: currentPage!,
        })
      );
    }
  };

  return (
    <div
      id="Points"
      className="content-page scroller-hide"
      onScroll={(e) => AppPageScrollDownLoadMore(e, loadMore)}
    >
      <div className="content-card pe-4 mt-4 ">
        <div className="header-title mt-1">
          <h4 className="card-title"> Points </h4>
        </div>

        <div
          className="points-container d-flex flex-column justify-content-center align-items-center mx-auto"
          style={{
            width: "100%",
            height: "236px",
            backgroundColor: "rgba(76, 175, 80, 0.10)",
          }}
        >
          <div
            className="points d-flex flex-column justify-content-center align-items-center"
            style={{
              width: "180px",
              height: "180px",
              background: "#4CAF50",
              color: "#fff",
              fontSize: "36px",
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="p-0 m-0"
            >
              <g clipPath="url(#clip0_847_826)">
                <path
                  d="M8.59715 10.8153C11.3116 10.8153 13.512 8.62056 13.512 5.91318C13.512 3.2058 11.3116 1.01104 8.59715 1.01104C5.88273 1.01104 3.68225 3.2058 3.68225 5.91318C3.68225 8.62056 5.88273 10.8153 8.59715 10.8153Z"
                  stroke="#fff"
                  strokeWidth="1.40061"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.93637 10.0387L5.08679 16.4185L8.59743 14.3175L12.1081 16.4185L11.2585 10.0317"
                  stroke="#fff"
                  strokeWidth="1.40061"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_847_826">
                  <rect
                    width="16.8511"
                    height="16.8073"
                    fill="white"
                    transform="translate(0.171753 0.311218)"
                  />
                </clipPath>
              </defs>
            </svg>
            <b
              className="point p-0 m-0"
              style={{
                fontSize: "36px",
              }}
            >
              {currentUser.points}
            </b>
            <small
              className="p-0 m-0"
              style={{
                fontSize: "16px",
              }}
            >
              POINTS
            </small>
          </div>
        </div>

        <Row>
          {pageState === AppState.LOADED ||
          pageState === AppState.LOADING_MORE ? (
            <>
              {data.length > 0 ? (
                data.map(
                  (
                    { class_points, name, id, logo_url }: PointClassResponse,
                    i: number
                  ) => (
                    <Col key={i} sm={4} className="mt-3">
                      <Link
                        to={RouteEndpoints.CLASS_POINTS(String(id))}
                        className="text-decoration-none text-dark"
                      >
                        <Card>
                          <Card.Body className="d-flex justify-content-between align-items-center gap-2">
                            <div
                              className="class-images"
                              style={{ width: "70px", height: "70px" }}
                            >
                              <img
                                style={{ width: "70px" }}
                                src={logo_url}
                                alt={name}
                              />
                            </div>
                            <h6>{name} </h6>
                            <div
                              className="rounded-pill p-2 d-flex align-items-center justify-content-center"
                              style={{
                                width: "35px",
                                height: "35px",
                                background: "rgba(4,161,0,0.09145665101978295)",
                                color: "#04a100",
                              }}
                            >
                              <b>{class_points}</b>
                            </div>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  )
                )
              ) : (
                <NoData title="noPoints" body="getMorePoints" />
              )}
            </>
          ) : (
            <Loader noCard imageLoader />
          )}
          {pageState === AppState.LOADING_MORE && <Loader noCard imageLoader />}
          <Col md={12}>
            {hasMore &&
              !(
                pageState === AppState.LOADING ||
                pageState === AppState.LOADING_MORE
              ) && (
                <div
                  className={`w-100 text-center shadow-xss rounded-xxl border-0 pt-3`}
                >
                  <button
                    className="text-decoration-none btn btn-primary"
                    onClick={() => loadMore()}
                  >
                    <Languages langKey="loadMore" />
                  </button>
                </div>
              )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Points;
