import React from "react";
import { UserResponse } from "../../services/api.response.service";

const UserProfile = ({
  details,
  setShowEditProfile,
}: {
  details: UserResponse;
  setShowEditProfile: (show: boolean) => void;
}) => {
  return (
    <>
      <div>
        <div className="user-detail mb-3 d-flex flex-column flex-sm-row align-items-center gap-3">
          <div className="profile-img">
            <img
              src={details.image_url}
              alt="profile-img1"
              className="avatar-130 img-fluid"
            />
          </div>
          <div className="d-flex flex-column gap-1 user">
            <h6>{details.name}</h6>
            <h6>@{details.username}</h6>
            <h6
              style={{ cursor: "pointer" }}
              onClick={() => setShowEditProfile(true)}
            >
              Update Profile <i className="las la-angle-right"></i>
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
