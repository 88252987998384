import React from "react";
import { Modal } from "react-bootstrap";
import { AnnouncementResponse } from "../services/api.response.service";
import moment from "moment";
import Languages from "./Languages";

interface props {
  details: AnnouncementResponse;
  setShowAnnouncementDetails: (event: undefined | AnnouncementResponse) => void;
}

const AnnouncementDetailsModal = ({
  details,
  setShowAnnouncementDetails,
}: props) => {
  return (
    <>
      {" "}
      <section id="EventDetailsOffCanvas">
        <Modal
          size="lg"
          show={!!details}
          onHide={() => setShowAnnouncementDetails(null!)}
          centered
        >
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <Modal.Body>
            <div className="blog-description d-flex flex-column gap-3">
              <small className="d-flex gap-3">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2917 2.16797H2.70833C2.11002 2.16797 1.625 2.65299 1.625 3.2513V10.8346C1.625 11.4329 2.11002 11.918 2.70833 11.918H10.2917C10.89 11.918 11.375 11.4329 11.375 10.8346V3.2513C11.375 2.65299 10.89 2.16797 10.2917 2.16797Z"
                    stroke="#9E9E9E"
                    strokeWidth="0.928571"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.625 5.41797H11.375"
                    stroke="#9E9E9E"
                    strokeWidth="0.928571"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.6665 1.08203V3.2487"
                    stroke="#9E9E9E"
                    strokeWidth="0.928571"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.3335 1.08203V3.2487"
                    stroke="#9E9E9E"
                    strokeWidth="0.928571"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {details?.date}
              </small>
              <img
                className="blog-img col-3"
                src={details?.resource_url}
                alt={details?.title}
                style={{
                  width: "290px",
                  height: "127px",
                  borderRadius: "5px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              />

              <div className="date mb-1">
                <h2 style={{ fontSize: "16px", fontWeight: 600 }}>
                  {details?.title}
                </h2>
              </div>
              <p className="mb-2" style={{ fontSize: "12px", fontWeight: 500 }}>
                {details?.details}
              </p>
            </div>
          </Modal.Body>
        </Modal>
      </section>{" "}
    </>
  );
};

export default AnnouncementDetailsModal;
