import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/remote-config";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCfrgVq8DdTBfNTbE2HbbKktERbpcXDZlU",
  authDomain: "ecampus-160f0.firebaseapp.com",
  databaseURL: "https://ecampus-160f0.firebaseio.com",
  projectId: "ecampus-160f0",
  storageBucket: "ecampus-160f0.appspot.com",
  messagingSenderId: "720716230294",
  appId: "1:720716230294:web:d50d7ddfb26cf1b5205cb7",
  measurementId: "G-PL0GD407SD",
};

const app = firebase.initializeApp(firebaseConfig);
export const remoteConfig = app.remoteConfig();
remoteConfig.settings = {
  fetchTimeoutMillis: 6000,
  minimumFetchIntervalMillis: 3600000,
};

export const auth = getAuth(app);
export const db = getFirestore(app);

remoteConfig
  .fetchAndActivate()
  .then(() => {})
  .catch((err) => {
    console.error(err);
  });
