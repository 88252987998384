import { combineReducers } from 'redux';
import auth from "./auth.reducer"
import setting from "./setting.reducer"
import main from "./main.reducer"

const rootReducer = combineReducers({
    auth,setting,main,
    // router: connectRouter(history)
});

export default rootReducer;