import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Col } from "react-bootstrap";
import ClassCard1 from "./ClassCard1";
import { ClassResponse } from "../services/api.response.service";
import { AppState } from "../services/app.service";
import Loader from "./Loader";
import Languages from "./Languages";
import NoData from "../pages/main/NoData";
import { RouteEndpoints } from "../services/route.service";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/states";

interface Props {
  title: string;
  viewAllLink?: string;
  data: Array<ClassResponse>;
  state: AppState;
  showUnAuthModal?: () => void;
}

function ClassesCardHList({
  title,
  viewAllLink,
  data,
  state,
  showUnAuthModal,
}: Props) {
  const location = useLocation();
  const { ecampus_id } = useSelector(
    (state: IRootState) => state.setting.user!
  );
  return (
    <Col lg={12} className="row m-0 p-0">
      <Col sm={12} className="d-flex justify-content-between mt-1">
        <div className="header-title">
          <h4 className="card-title" style={{ fontWeight: 500 }}>
            <Languages langKey={title} />
          </h4>
        </div>
        {viewAllLink && state === AppState.LOADED && (
          <div className="card-header-toolbar d-flex align-items-center">
            <Link
              to={ecampus_id ? viewAllLink : RouteEndpoints.UNAUTH_HOME}
              onClick={showUnAuthModal}
            >
              <Languages langKey={"viewAll"} />{" "}
              <i className="las la-angle-right"></i>
            </Link>
          </div>
        )}
      </Col>
      <Col
        sm={12}
        className={`${
          location.pathname === RouteEndpoints.UNAUTH_HOME ||
          location.pathname === RouteEndpoints.HOME
            ? "cards-container flex-row flex-nowrap"
            : "row"
        }  m-0 p-0`}
        style={{
          display:
            location.pathname === RouteEndpoints.UNAUTH_HOME ||
            location.pathname === RouteEndpoints.HOME
              ? "grid"
              : "",
          gridTemplateColumns:
            location.pathname === RouteEndpoints.UNAUTH_HOME ||
            location.pathname === RouteEndpoints.HOME
              ? `repeat(${
                  location.pathname === RouteEndpoints.UNAUTH_HOME ||
                  location.pathname === RouteEndpoints.HOME
                    ? data.length + 1
                    : 6
                }, 200px)`
              : "",
          gridGap:
            location.pathname === RouteEndpoints.UNAUTH_HOME ||
            location.pathname === RouteEndpoints.HOME
              ? "20px"
              : "",
        }}
      >
        {state === AppState.LOADING ? (
          <Loader noCard imageLoader />
        ) : data.length > 0 ? (
          data.map((item) => (
            <Col
              key={item.id}
              className={`mt-1 ${
                location.pathname === RouteEndpoints.UNAUTH_HOME ||
                location.pathname === RouteEndpoints.HOME
                  ? ""
                  : "col-12 col-sm-6 col-md-4 col-lg-3"
              }`}
            >
              <ClassCard1 data={item} showUnAuthModal={showUnAuthModal} />
            </Col>
          ))
        ) : (
          <NoData title={"noData"} />
        )}
      </Col>
    </Col>
  );
}

export default ClassesCardHList;
