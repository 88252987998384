import React from "react";
import Card from "./Card";
import { ContentProviderResponse } from "../services/api.response.service";
import { Link } from "react-router-dom";
import { RouteEndpoints } from "../services/route.service";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/states";

interface Props {
  data: ContentProviderResponse;
  showUnAuthModal?: () => void;
}

function ContentProviderCard({
  data: { id, name, logo_url, description, address, country, other_details, link_name },
  showUnAuthModal,
}: Props) {
  const { ecampus_id } = useSelector(
    (state: IRootState) => state.setting.user!
  );
  return (
    <>
      <Link
        to={
          // ecampus_id
            // ? RouteEndpoints.CONTENT_PROVIDER_DETAILS(String(id))
            // ?
              RouteEndpoints.CONTENT_PROVIDER_DETAIL(String(link_name))
            // : RouteEndpoints.UNAUTH_HOME
        }
        className="d-flex flex-column text-decoration-none"
        onClick={showUnAuthModal!}
      >
        {" "}
        <Card className="content-providers-container">
          <Card.Body className="d-flex flex-column gap-1 p-2">
            <div className="image-block position-relative d-flex justify-content-center align-items-center">
              <div
                style={{ backgroundImage: ` url(${logo_url})` }}
                className="img-fluid* w-100 rounded"
                // alt="product-img"
              />
            </div>
            {/* <div className="p-1 product-description mt-3 line-height-16">
                <h6 className="mb-1 fs-7">
                  {data.name.length > 21
                    ? data.name.slice(0, 21).concat("..")
                    : data.name}
                </h6>

              </div> */}

            <h6 className="mb-0 text-wrap text-start fs-4 fw-bold p-1">
              {name.length > 20 ? name.slice(0, 20).concat("...") : name}
            </h6>

            <h6 className="mb-0 text-body fs-7">
              {description.length > 50 ? description.slice(0, 50).concat("...") : description}
            </h6>
          </Card.Body>
        </Card>{" "}
      </Link>
    </>
  );
}

export default ContentProviderCard;
