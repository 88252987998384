import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RouteEndpoints } from "../../services/route.service";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Auth from "../../redux/actions/auth.action";
import { IRootState } from "../../redux/states";
import { AppState } from "../../services/app.service";

interface props {
  setOpenPassword: (show: boolean) => void;
}

const Settings = ({ setOpenPassword }: props) => {
  const { state, message } = useSelector(
    (state: IRootState) => state.auth.deleteAccount
  );

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="settings-container">
        <small>Settings</small>
        <div className="d-flex flex-column settings gap-4 pt-3">
          <Link
            to={RouteEndpoints?.INTERESTS}
            className="d-flex justify-content-between h6"
          >
            <span className="d-flex align-item-center gap-2">
              <i className="las la-thumbs-up"></i> Interests
            </span>
            <i className="las la-angle-right"></i>
          </Link>
          <Link
            to={RouteEndpoints?.POINTS}
            className="d-flex justify-content-between h6"
          >
            <span className="d-flex align-item-center gap-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 15C15.866 15 19 11.866 19 8C19 4.13401 15.866 1 12 1C8.13401 1 5 4.13401 5 8C5 11.866 8.13401 15 12 15Z"
                  stroke="#3C3C3B"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.21 13.89L7 23L12 20L17 23L15.79 13.88"
                  stroke="#3C3C3B"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Points
            </span>
            <i className="las la-angle-right"></i>
          </Link>
          <h6
            style={{
              cursor: "pointer",
            }}
            className="d-flex justify-content-between"
            onClick={() => setOpenPassword(true)}
          >
            <span className="d-flex align-item-center gap-2">
              <i className="las la-lock"></i> Change Password
            </span>
            <i className="las la-angle-right"></i>
          </h6>

          <h6
            style={{
              cursor: "pointer",
            }}
            className="d-flex justify-content-between"
            onClick={() => setOpen(true)}
          >
            {" "}
            <span className="d-flex align-item-center gap-2">
              <i className="las la-trash-alt"></i> Delete Account
            </span>
            <i className="las la-angle-right"></i>
          </h6>
        </div>{" "}
      </div>

      <Modal show={open} onHide={() => setOpen(true)} centered size="sm">
        <Modal.Body className="text-center">
          <h5>Are you sure you want to delete your account?</h5>

          <div className="d-grid gap-3 mx-auto pt-3">
            <Button
              variant="primary"
              onClick={() => dispatch(Auth.deleteAccount())}
              disabled={state === AppState.LOADING}
            >
              {state === AppState.LOADING && (
                <Spinner animation={"border"} size={"sm"} className="me-1" />
              )}
              Delete{" "}
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => setOpen(false)}
              disabled={state === AppState.LOADING}
            >
              cancel{" "}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Settings;
