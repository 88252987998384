import React from "react";
import Card from "./Card";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CourseResponse } from "../services/api.response.service";
import Languages from "./Languages";

interface Props {
  item: CourseResponse;
  view?: boolean;
}

function CourseCardItem({ item, view }: Props) {
  return (
    <Card className={view ? "view-course-card" : "course-card"}>
      <Card.Body>
        <h6>
          {" "}
          {item.title.length > 60
            ? item.title.slice(0, 60).concat("...")
            : item.title}
        </h6>

        {/* <div className="mt-2 text-body">
          <p className="mb-0">
            {item.description.length > 60
              ? item.description.slice(0, 60).concat("...")
              : item.description}
          </p>
        </div> */}
        {!view && (
          <div className="mt-2 d-flex gap-3">
            {/*{item.has_read && (*/}
            {/*  <span className="text-primary d-block line-height mt-0">*/}
            {/*    <OverlayTrigger*/}
            {/*      placement="top"*/}
            {/*      overlay={*/}
            {/*        <Tooltip>*/}
            {/*          {" "}*/}
            {/*          <Languages langKey="read" />{" "}*/}
            {/*        </Tooltip>*/}
            {/*      }*/}
            {/*    >*/}
            {/*      <i className="las la-book-reader fs-4" />*/}
            {/*    </OverlayTrigger>*/}
            {/*  </span>*/}
            {/*)}*/}

            {/*{item.has_practice && (*/}
            {/*  <span className="text-primary d-block line-height mt-0">*/}
            {/*    <OverlayTrigger*/}
            {/*      placement="top"*/}
            {/*      overlay={*/}
            {/*        <Tooltip>*/}
            {/*          {" "}*/}
            {/*          <Languages langKey="practice" />{" "}*/}
            {/*        </Tooltip>*/}
            {/*      }*/}
            {/*    >*/}
            {/*      <i className="las la-dumbbell fs-4" />*/}
            {/*    </OverlayTrigger>*/}
            {/*  </span>*/}
            {/*)}*/}

            {item.total_topics > 0 && (
              <small
                className="d-flex align-items-center gap-1 "
                data-toggle="tooltip"
                data-placement="top"
                title="Total Topics"
              >
                {/*<i className="las la-school" style={{ fontSize: 18 }} />*/}
                <span >{item.total_topics} <Languages langKey="topics" />{" "}</span>
              </small>
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default CourseCardItem;
