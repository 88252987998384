import React from 'react';
import {AppState} from "../services/app.service";
import {Spinner} from "react-bootstrap";
import loader from "../assets/images/page-img/page-load-loader.gif";

interface props {
    message?: string;
    state?: AppState
    noMarginTop?: boolean
    noMarginBottom?: boolean
    noCard?: boolean
    noPadding?: boolean
    size?: 'sm'
    animation?: 'border'|'grow'
    imageLoader?: boolean
}

function Loader({message,state=AppState.IDLE,noMarginTop,noMarginBottom,noCard,noPadding,size,animation='border',imageLoader}:props) {
    return (
        <div className={`${!noCard && "card"} w-100 text-center shadow-xss rounded-xxl border-0 
        ${!noPadding && "p-1"} ${!noMarginTop &&"mt-3"} ${!noMarginBottom &&"mb-3"} 
        ${ state === AppState.ERROR && 'bg-danger opacity-5'} ${state === AppState.SUCCESS && 'bg-success opacity-5'}`}>
            {message ?? ''}

            {imageLoader ?
                <div className="text-center">
                    <img src={loader} alt="loader" style={{height: "100px"}}/>
                </div>
                :
            <div className="text-center">
                <Spinner animation={animation} size={size}/>
            </div>}
        </div>
    );
}

export default Loader;