import React from "react";
import { ContentProviderResponse } from "../services/api.response.service";
import { AppState } from "../services/app.service";
import Loader from "./Loader";
import NoData from "../pages/main/NoData";
import ContentProviderCard from "./ContentProviderCard";
import NotFound from "./NotFound/NotFound";

interface Props {
  title?: string;
  data: Array<ContentProviderResponse>;
  state: AppState;
}

function ContentProvidersCardList({ data, state }: Props) {
  return (
    <div className="mt-4 p-0 row">
      {state === AppState.LOADING ? (
        <Loader noCard imageLoader />
      ) : data?.length > 0 &&
        (state === AppState.LOADED || state === AppState.LOADING_MORE) ? (
        (data as Array<ContentProviderResponse>).map((item, i) => (
          <div
            key={i}
            className="col-12 col-sm-4 col-md-3 col-lg-3 content-providers-main-container"
          >
            <ContentProviderCard data={item} />
          </div>
        ))
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <NotFound
            title="No content providers available"
            subTitle=""
            icon={
              <svg
                width="61"
                height="60"
                viewBox="0 0 61 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 42.5L30.5 55L55.5 42.5"
                  stroke="#DA3732"
                  stroke-opacity="0.6"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.5 30L30.5 42.5L55.5 30"
                  stroke="#DA3732"
                  stroke-opacity="0.6"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M30.5 5L5.5 17.5L30.5 30L55.5 17.5L30.5 5Z"
                  stroke="#DA3732"
                  stroke-opacity="0.6"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          />
        </div>
      )}
      {state === AppState.LOADING_MORE && <Loader noCard imageLoader />}
    </div>
  );
}

export default ContentProvidersCardList;
