import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Button, Form, Spinner, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import Actions from "../../redux/actions";
import { RouteEndpoints } from "../../services/route.service";
import { remoteConfig } from "../../services/firebase.service";
import {
  AppState,
  CheckAppLogoExist,
  CheckContentProviderIdExist,
  getAppLogo,
  getAppName,
  getKey,
  InfoLogger,
} from "../../services/app.service";
import constants from "../../utils/constants";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import NotifyAlert from "../../components/NotifyAlert";
import OAuth from "./OAuth";
import Languages from "../../components/Languages";
import logo from "../../assets/images/favicon.png";

const disableCaptcha =
  process.env.REACT_APP_ALLOW_CAPTCHA &&
  process.env.REACT_APP_ALLOW_CAPTCHA === "false";
function SignIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const recaptchaRef = useRef(null);
  const [token, setToken] = useState(disableCaptcha ? "no" : "");
  const [retries, setRetries] = useState(3);
  const dispatch = useDispatch();
  const { state, message } = useSelector(
    (state: IRootState) => state.auth.signIn
  );
  const { recaptchaSiteKey } = useSelector(
    (state: IRootState) => state.setting.keys
  );

  const [showPassword, setShowPassword] = useState(false);
  const [loadCaptcha, setLoadCaptcha] = useState(-3);

  const recaptchaSiteKey1 =
    remoteConfig.getValue(getKey(constants.RECAPTCHA_SITE_KEY)).asString() ??
    "";

  useEffect(() => {
    if (!recaptchaSiteKey && recaptchaSiteKey1) {
      dispatch(Actions.setting.setRecaptchaSiteKey(recaptchaSiteKey1));
    }
  }, [recaptchaSiteKey1]);

  useEffect(() => {
    try {
      loadRC();
    } catch (e) {
      console.error("captcha", e);
      setLoadCaptcha(loadCaptcha + 1);
    }
  }, [recaptchaSiteKey, loadCaptcha]);

  useEffect(() => {
    InfoLogger(state);
    if (state === AppState.RETRY && retries > 0) {
      InfoLogger(state, retries);
      setRetries((prevState) => prevState - 1);
      dispatch(Actions.auth.signIn({ username, password, token }));
      InfoLogger(state);
    }
  }, [state]);

  const loadRC = () => {
    if (loadCaptcha < 0 && recaptchaSiteKey.length > 0 && !disableCaptcha) {
      // @ts-ignore
      loadReCaptcha(recaptchaSiteKey, () => {
        InfoLogger("captcha ready");
        setLoadCaptcha(1);
      });
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    loadRC();
    e.preventDefault();
    dispatch(
      Actions.auth.signIn({ username, password, token: token ?? "yes" })
    );
  };

  return (
    <>
      <div className="sign-in-from vh-100 overflow-auto hide-scrollbar">
        <h1 className="mb-0">
          <Languages langKey="signIn" />
        </h1>

        {(state === AppState.ERROR || state === AppState.ERROR_INPUT) && (
          <NotifyAlert message={message} state={AppState.ERROR} timer={10000} />
        )}
        <Form onSubmit={handleSubmit} style={{marginTop: '1.5rem'}}>
          <Form.Group className="form-group mb-0">
            <Form.Label className="h6">
              {" "}
              <Languages langKey="username" /> / <Languages langKey="email" />
              {/* <Languages langKey="username" /> / <Languages langKey="email" /> / <Languages langKey="telecelID" /> */}
            </Form.Label>
            <Form.Group className="input-group">
              
              <Form.Control
                className={`mb-0 ${
                  state === AppState.ERROR_INPUT && "is-invalid"
                }`}
                style={{borderRadius: 8}}
                required
                onChange={(event) => setUsername(event.target.value)}
              />
            </Form.Group>
          </Form.Group>
          <Form.Group className="form-group mb-0" style={{marginTop: '1.12rem'}}>
            <Form.Label className="h6">
              <Languages langKey="password" />
            </Form.Label>
            <Form.Group className="input-group">
              <Form.Control
                type={showPassword ? "text" : "password"}
                className="mb-0 border-end-0"
                style={{borderTopLeftRadius: 8, borderBottomLeftRadius: 8}}
                required
                onChange={(event) => setPassword(event.target.value)}
              />
              <span
                className="input-group-text bg-transparent border-start-0"
                onClick={() => setShowPassword(!showPassword)}
                style={{borderTopRightRadius: 8, borderBottomRightRadius: 8}}
              >
                <i className={`las la-${showPassword ? "eye-slash" : "eye"}`} />
              </span>
            </Form.Group>
          </Form.Group>
          <Link to={RouteEndpoints.FORGOT_PASSWORD} className="float-end pe-4 mb-0" style={{color: '#A74D2D',marginTop: '1.12rem'}}>
              <Languages langKey="forgotPassword" />?
            </Link>
          <div className="d-inline-block w-100 mb-0" style={{marginTop: '1.5rem'}}>
            <Button variant="primary" type="submit" className="w-100 mb-0 mt-0" style={{backgroundColor: '#DF6438', borderColor: '#DF6438', height: 50,borderRadius: 8}}>
              {state === AppState.LOADING && (
                <Spinner animation={"border"} size={"sm"} className="me-1" />
              )}
              <Languages langKey="login" />{" "}
            </Button>
          </div>

          <div className="sign-info d-flex flex-column mb-5">

            {!CheckContentProviderIdExist() && <OAuth />}

            <Link to={RouteEndpoints.SIGN_UP} className="dark-color d-inline-flex line-height-2 justify-content-center" style={{color: '#A74D2D', fontWeight: 'bold', marginTop: '1.5rem'}}>
              <Languages langKey="noAccount" /> ?{" "} <Languages langKey="signUp" />
            </Link>

            <div className="w-100 d-flex justify-content-center mt-4">
              <Link to="#" className="text-dark" style={{fontWeight: 'bold'}}>
                  <Image src={CheckAppLogoExist() ? getAppLogo() : logo} style={{width: 30}} alt="logo" /> {getAppName()}
                </Link>
              </div>
          </div>
        </Form>
      </div>
      {loadCaptcha && !disableCaptcha && (
        <ReCaptcha
          ref={recaptchaRef}
          action="submit"
          sitekey={recaptchaSiteKey}
          verifyCallback={(e) => setToken(e)}
        />
      )}
    </>
  );
}

export default SignIn;
