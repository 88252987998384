import React from "react";
import { Card } from "react-bootstrap";
import { UserResponse } from "../../services/api.response.service";
import { ellipsisText } from "../../services/app.service";

const UserDetailsCard = ({ details }: { details: UserResponse }) => {
  return (
    <div className="overflow-hidden">
      <div className="user-card-details-container">
        <Card className="d-flex justify-content-center align-items-center user-card">
          <small>eCampus ID</small>
          <h6>{details?.ecampus_id}</h6>
        </Card>
        {
            details?.third_party === 'Telecel' &&
            <Card className="d-flex justify-content-center align-items-center user-card">
              <small>Telecel ID</small>
              <h6>{details?.firebase_uid}</h6>
            </Card>
        }
        <Card className="d-flex justify-content-center align-items-center user-card points">
          <small>Points</small>
          <h6>{details?.points}</h6>
        </Card>
        <Card className="d-flex justify-content-center align-items-center user-card">
          <small>Institute</small>
          <h6>{details?.institute}</h6>
        </Card>{" "}
        <Card className="d-flex justify-content-center align-items-center user-card">
          <small>Location</small>
          <h6>
            {details?.city}, {details?.country}
          </h6>
        </Card>
        {/*<Card className="d-flex justify-content-center align-items-center user-card">*/}
        {/*  <small>Phone Number</small>*/}
        {/*  <h6>{details?.phone_number}</h6>*/}
        {/*</Card>*/}
        {/*<Card className="d-flex justify-content-center align-items-center user-card text-truncate">*/}
        {/*  <small>Email</small>*/}
        {/*  <h6> {ellipsisText(details?.email)}</h6>*/}
        {/*</Card>*/}
      </div>
      <hr />
    </div>
  );
};

export default UserDetailsCard;
