import { createStore, applyMiddleware, compose } from "redux";

import rootReducer from "./reducers";
import rootState from "./states";
import rootSaga from "./sagas";
/*import {History} from "history";
import {routerMiddleware} from "connected-react-router";
import storage from "redux-persist/lib/storage";
import { PersistConfig } from 'redux-persist/es/types'*/
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [
  rootSaga,
  // routerMiddleware(history)
];

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = createStore(rootReducer,
//     rootState,composeEnhancers(applyMiddleware(...middlewares)));

const store = createStore(
  persistedReducer,
  rootState,
  composeEnhancers(applyMiddleware(...middlewares))
);

const persistor = persistStore(store);

export { persistor };

export default store;

/*
const persistConfig: PersistConfig<any> = {
    blacklist: [
        'router',
    ],
    key: 'ec-root',
    storage,
}

const configureStore = (history:History) => {
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const middlewares = [rootSaga,
        routerMiddleware(history)
    ]

    const persistedReducers = persistReducer(persistConfig, rootReducer(history))

    const store = createStore(rootReducer(history),
        rootState,composeEnhancers(applyMiddleware(...middlewares)));

    return {store}
}

export default configureStore

export const configureStor = (initialState = {}, history: any) => {
    const rootReducer: any = appReducer(history)
    const sagaMiddleware = createSagaMiddleware()
    const composeEnhancers: typeof compose =
        (window as unknown as IWindow).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    const middlewares = [sagaMiddleware, routerMiddleware(history)]

    const enhancers = [applyMiddleware(...middlewares)]
    const persistedReducers = persistReducer(persistConfig, rootReducer)

    const store = createStore(persistedReducers, initialState, composeEnhancers(...enhancers))
    const persistor = persistStore(store)

    sagaManager.startSagas(sagaMiddleware)

    if (process.env.NODE_ENV === 'development' && module.hot) {
        // HMR reducers
        module.hot.accept('./appReducer', () => {
            store.replaceReducer(require('./appReducer').appReducer)
        })
        // HMR sagas (NB! Sagas internal state won't persist)
        module.hot.accept('./sagaManager', () => {
            sagaManager.cancelSagas(store)
            require('./sagaManager').sagaManager.startSagas(sagaMiddleware)
        })
    }

    return { store, persistor }
}*/
