import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../redux/actions";
import { IRootState } from "../redux/states";
import { getLanguageByDefault, LANGUAGES } from "../services/app.service";

const AuthLanguageSwitch = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state);

  const language = state.setting.language;

  const handleLang = (lang: LANGUAGES) => {
    dispatch(Actions.setting.setLanguage(lang));
  };
  const getLanguage = getLanguageByDefault()
  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center me-5 w-100"
        style={{ top: 0, position: "absolute", height: 56, cursor: "pointer" }}
      >
        <div
          className={`w-100 h-100 d-flex justify-content-center align-items-center ${language === LANGUAGES.EN ? "border-bottom border-active" : ""}`}
          style={{ backgroundColor: language === LANGUAGES.EN ? "#F9F9F9" : 'none' }}
          onClick={() => handleLang(LANGUAGES.EN)}
        >
          <h6>
            {getLanguage.english}
          </h6>
        </div>
        <div
          className={`w-100 h-100 d-flex justify-content-center align-items-center ${language === LANGUAGES.FR ? "border-bottom border-active" : ""}`}
          style={{ backgroundColor: language === LANGUAGES.FR ? "#F9F9F9" : 'none' }}
          onClick={() => handleLang(LANGUAGES.FR)}
        >
          <h6>
            {getLanguage.french}
          </h6>
        </div>
      </div>
    </>
  );
};

export default AuthLanguageSwitch;
