import React, { FormEvent, useState } from "react";
import { Button, Form, Modal, Row, Spinner } from "react-bootstrap";
import Languages from "../Languages";
import { useDispatch } from "react-redux";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import { UserResponse } from "../../services/api.response.service";
import NotifyAlert from "../NotifyAlert";
import { AppState } from "../../services/app.service";

interface props {
  openPassword: boolean;
  handleShowChangePassword: () => void;
  details: BaseState<UserResponse>;
}

const UpdatePassword = ({
  openPassword,
  handleShowChangePassword,
  details,
}: props) => {
  const dispatch = useDispatch();

  const [updatePassword, setUpdatePassword] = useState<{
    old_password: string;
    new_password: string;
    new_password_confirmation: string;
  }>();

  const handleSubmitChangePassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      Main.postPageData({
        stateKey: StateKeys.PROFILE,
        apiEndpoint: ApiEndpoint.UPDATE_PASSWORD,
        req: updatePassword,
      })
    );
  };

  return (
    <>
      <Modal show={openPassword} onHide={handleShowChangePassword}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <Languages langKey="changePass" />
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitChangePassword}>
          {(details.pageState === AppState.ERROR ||
            details.pageState === AppState.ERROR_INPUT) && (
            <NotifyAlert
              message={details.message}
              state={AppState.ERROR}
              timer={10000}
            />
          )}
          <Modal.Body>
            <Row className="align-items-center">
              <Form.Group className="form-group col-sm-12">
                <Form.Label htmlFor="oldPassword" className="form-label">
                  <Languages langKey="oldPass" />:
                </Form.Label>
                <Form.Control
                  type="password"
                  className="form-control"
                  id="oldPassword"
                  defaultValue={updatePassword?.old_password}
                  onChange={({ target: { value } }) =>
                    setUpdatePassword((prev) => ({
                      ...prev!,
                      old_password: value,
                    }))
                  }
                  required
                />
              </Form.Group>
              <Form.Group className="form-group col-sm-12">
                <Form.Label htmlFor="newPassword" className="form-label">
                  <Languages langKey="newPass" />:
                </Form.Label>
                <Form.Control
                  type="password"
                  className="form-control"
                  id="newPassword"
                  defaultValue={updatePassword?.new_password}
                  onChange={({ target: { value } }) =>
                    setUpdatePassword((prev) => ({
                      ...prev!,
                      new_password: value,
                    }))
                  }
                  required
                />
              </Form.Group>
              <Form.Group className="form-group col-sm-12">
                <Form.Label htmlFor="confirmPassword" className="form-label">
                  <Languages langKey="confirmPass" />:
                </Form.Label>
                <Form.Control
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  defaultValue={updatePassword?.new_password_confirmation}
                  onChange={({ target: { value } }) =>
                    setUpdatePassword((prev) => ({
                      ...prev!,
                      new_password_confirmation: value,
                    }))
                  }
                  required
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              type="button"
              onClick={handleShowChangePassword}
              disabled={details.pageState === AppState.LOADING}
            >
              <Languages langKey="cancel" />
            </Button>
            <Button
              type="submit"
              className="btn btn-primary ms-2"
              disabled={details.pageState === AppState.LOADING}
            >
              {details.pageState === AppState.LOADING && (
                <Spinner animation={"border"} size={"sm"} className="me-1" />
              )}
              <Languages langKey="save" />
            </Button>
          </Modal.Footer>{" "}
        </Form>{" "}
      </Modal>
    </>
  );
};

export default UpdatePassword;
