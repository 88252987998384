import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { GetFullURL } from "../../services/app.service";
import NotFound from "../NotFound/NotFound";
import moment from "moment";
import { BaseState } from "../../redux/states/main.state";
import {
  TestResponse,
  TestResultResponse,
} from "../../services/api.response.service";
import Languages from "../Languages";

interface props {
  testResults: BaseState<Array<TestResultResponse>>;
  test: TestResponse;
  setShowTest?: (show: boolean) => void;
}

const TestResults = ({ testResults, test, setShowTest }: props) => {
  return (
    <>
      {" "}
      <>
        <Row className="mt-3">
          {testResults.data.length > 0 ? (
            testResults.data.map((item, i) => (
              <Col key={i} sm={12} md={6} className="mt-3">
                <Card
                  className="test-result-card p-3"
                  style={{ borderRadius: "1.5rem" }}
                >
                  <Card.Body>
                    <div className="d-flex  gap-3">
                      <div
                        className="text-white rounded-pill me-2 d-flex flex-column align-items-center justify-content-center"
                        style={{
                          background:
                            item.marks > test?.question_limit
                              ? "rgba(76, 175, 80, 0.2)"
                              : "rgba(234, 152, 63, 0.2)",
                          width: "86px",
                          height: "86px",
                        }}
                      >
                        <span
                          className="d-block line-height mt-0 mx-auto"
                          style={{
                            color:
                              item.marks > test?.question_limit
                                ? "#4CAF50"
                                : "#DF6438",
                          }}
                        >
                          <i className="las la-trophy fs-4" />
                        </span>
                        <h5
                          style={{
                            color:
                              item.marks > test?.question_limit
                                ? "#4CAF50"
                                : "#DF6438",
                          }}
                        >{`${item.marks}/${test?.question_limit}`}</h5>
                      </div>
                      <div className="d-flex flex-column">
                        <div className="d-flex">
                          <span
                            className="d-block line-height mt-0 me-1"
                            style={{ color: "#3C3C3B" }}
                          >
                            <i className="las la-calendar-day fs-4" />
                          </span>
                          <h6>
                            {moment(item.created_at).format("ddd, Do MMM YYYY")}
                          </h6>
                        </div>
                        <div className="d-flex">
                          <span
                            className="d-block line-height mt-0 me-1"
                            style={{ color: "#3C3C3B" }}
                          >
                            <i className="las la-file-alt fs-4" />
                          </span>
                          <h6>{test?.name}</h6>
                        </div>
                        <div className="d-flex mt-1">
                          <span
                            className="d-block line-height mt-0 me-1"
                            style={{ color: "#3C3C3B" }}
                          >
                            <i className="las la-stopwatch fs-4" />
                          </span>
                          <h6>{`${item.time_taken}mins/${test?.duration}mins`}</h6>
                        </div>
                      </div>

                      <h6
                        className="text-white rounded-pill px-3"
                        style={{
                          backgroundColor:
                            item.marks > test?.question_limit
                              ? "#4CAF50"
                              : "#DF6438",
                          position: "absolute",
                          bottom: "20px",
                          height: "fit-content",
                          right: "30px",
                        }}
                      >
                        {item.marks > test?.question_limit
                          ? "Passed"
                          : "Failed"}
                      </h6>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <NotFound
              icon={"la-file-alt"}
              title="There are no test results to be dispalyed"
              subTitle=" You have not taken any tests for BECE 2011 yet."
              button={
                <Button
                  className="btn rounded"
                  variant="outline-primary"
                  onClick={() => setShowTest!(true)}
                >
                  <Languages langKey="takeTestNow" />
                </Button>
              }
            />
          )}
        </Row>
      </>
    </>
  );
};

export default TestResults;
