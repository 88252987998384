import React from 'react'
import {Image,Button} from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import mail from '../../assets/images/login/mail.png'
import {RouteEndpoints} from "../../services/route.service";
import {getLanguageByDefault} from "../../services/app.service";

function ConfirmMail(){
   const history =useHistory()
  const getLanguage = getLanguageByDefault()
   return (
       <div className="sign-in-from">
           <Image src={mail} width="80" alt=""/>
           <h1 className="mt-3 mb-0">{getLanguage.success} !</h1>
           <p>{getLanguage.aMailHasBeenSend}</p>
           <div className="d-inline-block w-100">
               <Button type="button"  onClick={() => history.push(RouteEndpoints.SIGN_IN)} variant="primary" className="mt-3">{getLanguage.signIn}</Button>
           </div>
       </div>
    )
}

export default ConfirmMail;
