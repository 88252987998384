import React from "react";
import { Card, Col } from "react-bootstrap";
import { CategoryResponse } from "../services/api.response.service";
import { AppState } from "../services/app.service";
import Loader from "./Loader";
import NoData from "../pages/main/NoData";
import Languages from "./Languages";
import { Link } from "react-router-dom";
import { RouteEndpoints } from "../services/route.service";

interface Props {
  title?: string;
  data: Array<CategoryResponse>;
  state: AppState;
}

const AllCategoryList = ({ title, data, state }: Props) => {
  return (
    <>
      {" "}
      <Col lg={12} className="row m-0 p-0">
        {title && (
          <Col sm={12} className="d-flex justify-content-between mt-1">
            <div className="header-title">
              <h4 className="card-title text-capitalize">
                <Languages langKey={title} />
              </h4>
            </div>
          </Col>
        )}
        <Col sm={12} className="row m-0 p-0">
          {(state === AppState.LOADED || state === AppState.LOADING_MORE) && (
            <>
              {data.length > 0 ? (
                data.map((item, i) => (
                  <Col key={i} sm={6} md={3} lg={3} xl={2} className="mt-1">
                    {/* <Link
                      to={RouteEndpoints.CATEGORY_CLASSES(String(item?.id))}
                      className="category-btn d-flex justify-content-center align-items-center"
                    >
                      {item?.name}
                    </Link> */}

                    <Link
                      // to={RouteEndpoints.CATEGORY_CLASSES(`${item?.id}`)}
                      to={RouteEndpoints.CATEGORY_DETAILS(`${item?.link_name}`)}
                      className="text-decoration-none"
                    >
                      <Card className="interest mb-0 d-flex justify-content-center align-items-center">
                        <Card.Body>
                          <h5 className="text-white"> {item?.name}</h5>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                ))
              ) : (
                <NoData title="noCatAvailable" />
              )}
            </>
          )}
        </Col>
        <Col sm={12} className="row m-0 p-0">
          {(state === AppState.LOADING || state === AppState.LOADING_MORE) && (
            <Loader noCard imageLoader />
          )}
        </Col>
      </Col>{" "}
    </>
  );
};

export default AllCategoryList;
