import {
  ForgotPasswordRequest,
  OAuthRequest,
  RegisterRequest,
  SignInRequest,
  SignUpRequest,
  TelecelSignInRequest,
} from "../../services/api.request.service";

export const types = {
  //Sign in
  SIGN_IN: "SIGN_IN_REQUEST",
  SIGN_IN_STATE: "SIGN_IN_STATE",
  SIGN_IN_MESSAGE: "SIGN_IN_MESSAGE",

  //Sign up
  SIGN_UP: "SIGN_UP_REQUEST",
  SIGN_UP_STATE: "SIGN_UP_STATE",
  SIGN_UP_MESSAGE: "SIGN_UP_MESSAGE",

  //OAuth
  OAUTH: "OAUTH_REQUEST",
  OAUTH_STATE: "OAUTH__STATE",
  OAUTH_MESSAGE: "OAUTH__MESSAGE",

  //Register
  REGISTER: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERROR: "REGISTER_ERROR",
  REGISTER_ERROR_INPUT: "REGISTER_ERROR_INPUT",
  REGISTER_LOADER: "REGISTER_LOADER",
  REGISTER_MESSAGE: "REGISTER_MESSAGE",
  REGISTER_STATE: "REGISTER_STATE",

  //Forgot password
  FORGOT_PASSWORD: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_STATE: "FORGOT_PASSWORD_STATE",
  FORGOT_PASSWORD_MESSAGE: "FORGOT_PASSWORD_MESSAGE",

  //Logout
  LOGOUT: "LOGOUT_REQUEST",

  //Sign in
  DELETE_ACCOUNT: "DELETE_ACCOUNT_REQUEST",
  DELETE_ACCOUNT_STATE: "DELETE_ACCOUNT_STATE",
  DELETE_ACCOUNT_MESSAGE: "DELETE_ACCOUNT_MESSAGE",

  //Telecel
  TELECEL_SIGN_IN: "TELECEL_SIGN_IN_REQUEST",
  TELECEL_SIGN_IN_STATE: "TELECEL_SIGN_IN_STATE",
  TELECEL_SIGN_IN_MESSAGE: "TELECEL_SIGN_IN_MESSAGE",
  TELECEL_SIGN_IN_SHOW_CODE: "TELECEL_SIGN_IN_SHOW_CODE",
  TELECEL_SIGN_IN_NEW_USER: "TELECEL_SIGN_IN_NEW_USER",
};

const Auth = {
  //Sign in
  signIn: (payload: SignInRequest) => ({ type: types.SIGN_IN, payload }),
  setSignInState: (payload: number) => ({ type: types.SIGN_IN_STATE, payload }),
  setSignInMessage: (payload: string) => ({
    type: types.SIGN_IN_MESSAGE,
    payload,
  }),

  //Sign up
  signUp: (payload: SignUpRequest) => ({ type: types.SIGN_UP, payload }),
  setSignUpState: (payload: number) => ({ type: types.SIGN_UP_STATE, payload }),
  setSignUpMessage: (payload: string) => ({
    type: types.SIGN_UP_MESSAGE,
    payload,
  }),

  //OAuth
  oAuth: (payload: OAuthRequest) => ({ type: types.OAUTH, payload }),
  setOAuthState: (payload: number) => ({ type: types.OAUTH_STATE, payload }),
  setOAuthMessage: (payload: string) => ({
    type: types.OAUTH_MESSAGE,
    payload,
  }),

  //Register
  register: (payload: RegisterRequest) => ({ type: types.REGISTER, payload }),
  setRegisterLoader: (payload: boolean) => ({
    type: types.REGISTER_LOADER,
    payload,
  }),
  setRegisterError: (payload: boolean) => ({
    type: types.REGISTER_ERROR,
    payload,
  }),
  setRegisterErrorInput: (payload: boolean) => ({
    type: types.REGISTER_ERROR_INPUT,
    payload,
  }),
  setRegisterMessage: (payload: string) => ({
    type: types.REGISTER_MESSAGE,
    payload,
  }),
  setRegisterState: (payload: number) => ({
    type: types.REGISTER_STATE,
    payload,
  }),

  //Forgot password
  forgotPassword: (payload: ForgotPasswordRequest) => ({
    type: types.FORGOT_PASSWORD,
    payload,
  }),
  setForgotPasswordState: (payload: number) => ({
    type: types.FORGOT_PASSWORD_STATE,
    payload,
  }),
  setForgotPasswordMessage: (payload: string) => ({
    type: types.FORGOT_PASSWORD_MESSAGE,
    payload,
  }),

  //Logout
  logout: () => ({ type: types.LOGOUT }),

  //Delete account
  deleteAccount: () => ({ type: types.DELETE_ACCOUNT }),
  setDeleteAccountState: (payload: number) => ({
    type: types.DELETE_ACCOUNT_STATE,
    payload,
  }),
  setDeleteAccountMessage: (payload: string) => ({
    type: types.SIGN_IN_MESSAGE,
    payload,
  }),

  //Telecel
  telecelSignIn: (payload: TelecelSignInRequest) => ({
    type: types.TELECEL_SIGN_IN,
    payload,
  }),
  setTelecelSignInState: (payload: number) => ({
    type: types.TELECEL_SIGN_IN_STATE,
    payload,
  }),
  setTelecelSignInMessage: (payload: string) => ({
    type: types.TELECEL_SIGN_IN_MESSAGE,
    payload,
  }),
  setTelecelSignInShowCode: (payload: boolean) => ({
    type: types.TELECEL_SIGN_IN_SHOW_CODE,
    payload,
  }),
  setTelecelSignInNewUser: (payload: boolean) => ({
    type: types.TELECEL_SIGN_IN_NEW_USER,
    payload,
  }),
};

export default Auth;
