import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import Main from "../../redux/actions/main.action";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import ApiEndpoint from "../../services/api.endpoint.service";
import { AppState } from "../../services/app.service";
import {
  Point,
  PointClassDetailsResponse,
  ClassResponse,
} from "../../services/api.response.service";
import NoData from "./NoData";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import { RouteEndpoints } from "../../services/route.service";
import GoBack from "../../components/GoBack";

const ClassPoints = () => {
  let { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const state = useSelector((state: IRootState) => state.main);

  const user_points_class_details: BaseState<Array<PointClassDetailsResponse>> =
    state[StateKeys.USER_POINTS_CLASS_DETAILS];
  const activeClass: BaseState<ClassResponse> = state[StateKeys.ACTIVE_CLASS];

  useEffect(() => {
    if (
      activeClass.pageState !== AppState.LOADED ||
      (activeClass.pageState === AppState.LOADED &&
        String((activeClass.data as ClassResponse).id) !== id)
    ) {
      dispatch(
        Main.getPageObjectData({
          stateKey: StateKeys.ACTIVE_CLASS,
          apiEndpoint: ApiEndpoint.ACTIVE_CLASS(Number(id)),
        })
      );
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.USER_POINTS_CLASS_DETAILS,
          apiEndpoint: ApiEndpoint.USER_POINTS_CLASS_DETAILS(Number(id)),
          clear: true,
        })
      );
    }

    // if (
    //   user_points_class_details.pageState !== AppState.LOADED &&
    //   user_points_class_details.pageState !== AppState.LOADING
    // ) {
    //   dispatch(
    //     Main.getPageArrayData({
    //       stateKey: StateKeys.USER_POINTS_CLASS_DETAILS,
    //       apiEndpoint: ApiEndpoint.USER_POINTS_CLASS_DETAILS(Number(id)),
    //     })
    //   );
    // }
  }, [id]);

  const calculatePoint = (points: Array<Point>) => {
    let sum = { read: 0, practice: 0, watch: 0, listen: 0, discuss: 0 };
    points.forEach((e, i) => {
      // @ts-ignore
      sum = {
        ...sum,
        [e.where.toLowerCase()]:
          // @ts-ignore
          Number(sum[e.where.toLowerCase()] ?? 0) + e.marks,
      };
    });
    let total = 0;
    Object.keys(sum).forEach((e: string) => {
      // @ts-ignore
      total += sum[e];
    });

    return { ...sum, total };
  };

  const calculateTotalPoint = () => {
    const points = user_points_class_details.data.map(
      (classContent) => classContent.content_points
    );

    return (points || []).reduce((a, b) => a + b, 0);
  };

  return (
    <>
      <section id="ClassPoints" className="content-page scroller-hide">
        {user_points_class_details.pageState === AppState.LOADED ||
        user_points_class_details.pageState === AppState.LOADING_MORE ? (
          <>
            <GoBack />
            <div className="pe-4">
              <div
                className="points-container d-flex flex-column justify-content-center align-items-start mx-auto mt-0"
                style={{
                  width: "100%",
                  height: "296px",
                  backgroundColor: "rgba(76, 175, 80, 0.10)",
                }}
              >
                <div className="header-title ps-3">
                  <h4 className="card-title"> {activeClass.data?.name} </h4>
                </div>
                <div
                  className="points d-flex flex-column justify-content-center align-items-center mx-auto"
                  style={{
                    width: "180px",
                    height: "180px",
                    color: "#04a100",
                    border: "1px solid #04a100",
                    fontSize: "36px",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="p-0 m-0"
                  >
                    <g clipPath="url(#clip0_847_826)">
                      <path
                        d="M8.59715 10.8153C11.3116 10.8153 13.512 8.62056 13.512 5.91318C13.512 3.2058 11.3116 1.01104 8.59715 1.01104C5.88273 1.01104 3.68225 3.2058 3.68225 5.91318C3.68225 8.62056 5.88273 10.8153 8.59715 10.8153Z"
                        stroke="#04a100"
                        strokeWidth="1.40061"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.93637 10.0387L5.08679 16.4185L8.59743 14.3175L12.1081 16.4185L11.2585 10.0317"
                        stroke="#04a100"
                        strokeWidth="1.40061"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_847_826">
                        <rect
                          width="16.8511"
                          height="16.8073"
                          fill="white"
                          transform="translate(0.171753 0.311218)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <b
                    className="point p-0 m-0"
                    style={{
                      fontSize: "36px",
                    }}
                  >
                    {calculateTotalPoint()}
                  </b>
                  <small
                    className="p-0 m-0"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    POINTS
                  </small>
                </div>
              </div>

              <Row>
                <>
                  {user_points_class_details.data.length > 0 ? (
                    user_points_class_details.data.map(
                      (
                        {
                          content_points,
                          title,
                          points,
                          class_id,
                        }: PointClassDetailsResponse,
                        i: number
                      ) => (
                        <Col key={i} md={6} className="mt-3">
                          <Link
                            to={RouteEndpoints.CLASS_COURSES(String(class_id))}
                            className="text-decoration-none text-dark"
                          >
                            <Card>
                              <Card.Body className="d-flex flex-column gap-3">
                                <div className="d-flex justify-content-between align-items-center gap-2 border-bottom pb-2">
                                  <h6>{title} </h6>
                                  <div
                                    className="rounded-circle d-flex align-items-center justify-content-center"
                                    style={{
                                      width: "35px",
                                      height: "35px",
                                      background:
                                        "rgba(4,161,0,0.09145665101978295)",
                                      color: "#04a100",
                                    }}
                                  >
                                    <b>{content_points}</b>
                                  </div>
                                </div>
                                <div>
                                  <div
                                    className="mt-2 d-flex justify-content-start align-items-center"
                                    key={i}
                                  >
                                    <span className="content-point-card d-flex flex-column justify-content-center align-items-center  line-height mt-0 me-2">
                                      <i className="las la-book-reader fs-4" />
                                      <p className="p-0 m-0">Read</p>

                                      <span style={{ color: "#4CAF50" }}>
                                        {" "}
                                        {calculatePoint(points).read}
                                      </span>
                                    </span>
                                    <span className="content-point-card d-flex flex-column justify-content-center align-items-center  line-height mt-0 me-2">
                                      <i className="las la-headphones-alt fs-4" />
                                      <p className="p-0 m-0">Listen</p>

                                      <span style={{ color: "#4CAF50" }}>
                                        {" "}
                                        {calculatePoint(points).listen}
                                      </span>
                                    </span>
                                    <span className="content-point-card d-flex flex-column justify-content-center align-items-center  line-height mt-0 me-2">
                                      <i className="lab la-youtube fs-4" />
                                      <p className="p-0 m-0">Watch</p>

                                      <span style={{ color: "#4CAF50" }}>
                                        {" "}
                                        {calculatePoint(points).watch}
                                      </span>
                                    </span>
                                    <span className="content-point-card d-flex flex-column justify-content-center align-items-center  line-height mt-0 me-2">
                                      <i className="las la-comments fs-4" />
                                      <p className="p-0 m-0">Practice</p>

                                      <span style={{ color: "#4CAF50" }}>
                                        {" "}
                                        {calculatePoint(points).practice}
                                      </span>
                                    </span>
                                    <span className="content-point-card d-flex flex-column justify-content-center align-items-center  line-height mt-0 me-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M11.5 20h-6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v5a1 1 0 0 0 2 0V8.94a1.31 1.31 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19a.29.29 0 0 0-.1 0a1.1 1.1 0 0 0-.31-.11H5.5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h6a1 1 0 0 0 0-2Zm1-14.59L15.09 8H13.5a1 1 0 0 1-1-1ZM7.5 14h6a1 1 0 0 0 0-2h-6a1 1 0 0 0 0 2Zm4 2h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2Zm-4-6h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2Zm13.71 6.29a1 1 0 0 0-1.42 0l-3.29 3.3l-1.29-1.3a1 1 0 0 0-1.42 1.42l2 2a1 1 0 0 0 1.42 0l4-4a1 1 0 0 0 0-1.42Z"
                                        />
                                      </svg>
                                      <p className="p-0 m-0">Discuss</p>

                                      <span style={{ color: "#4CAF50" }}>
                                        {" "}
                                        {calculatePoint(points).discuss}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </Link>
                        </Col>
                      )
                    )
                  ) : (
                    <NoData title="noPoints" body="getMorePoints" />
                  )}
                </>
              </Row>
            </div>
          </>
        ) : (
          <Loader noCard imageLoader />
        )}
      </section>
    </>
  );
};

export default ClassPoints;
