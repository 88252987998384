import React, { FormEvent, useEffect, useState } from "react";
import {Button, Col, Form, Offcanvas, Row, Spinner} from "react-bootstrap";
import Languages from "../Languages";
import NotifyAlert from "../NotifyAlert";
import {
  AppState,
  CheckInstitutionsExist,
  getInstitutions,
  getLanguageByDefault,
} from "../../services/app.service";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import { UserResponse } from "../../services/api.response.service";

import { useDispatch, useSelector } from "react-redux";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";
import { IRootState } from "../../redux/states";
import { Countries } from "../../utils/constants";

interface props {
  showEditProfile: boolean;
  handleCloseEditProfile: () => void;
  details: BaseState<UserResponse>;
}

interface User extends UserResponse {
  // about: string;
}

const EditProfile = ({
  showEditProfile,
  handleCloseEditProfile,
  details,
}: props) => {
  const getLanguage = getLanguageByDefault();

  const dispatch = useDispatch();
  const [user, setUser] = useState<User>({
    ...(details?.data as unknown as User),
  });
  const [profilePicture, setProfilePicture] = useState<Blob | MediaSource>();

  useEffect(() => {
    if (details.pageState === AppState.LOADED) {
      setProfilePicture(null!);

      setUser({
        ...(details?.data as unknown as User),
      });
    }
  }, [details.pageState]);
  const handleSubmitEditProfile = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      Main.postPageData({
        stateKey: StateKeys.PROFILE,
        apiEndpoint: ApiEndpoint.UPDATE_USER,
        req: user,
      })
    );
  };

  const upload = () => {
    if (typeof profilePicture !== "string") {
      dispatch(
        Main.postPageData({
          stateKey: StateKeys.PROFILE,
          apiEndpoint: ApiEndpoint.UPDATE_USER_IMAGE,
          req: {
            image: profilePicture,
          },
          isFormData: true,
          config: {
            headers: { "Content-Type": "multipart/form-data" },
          },
        })
      );
    }
  };

  return (
    <>
      {" "}
      <Offcanvas
        show={showEditProfile}
        onHide={() => {
          handleCloseEditProfile();
          setProfilePicture(null!);
        }}
        placement="end"
        style={{ width: "487px" }}
        scroll={true}
        backdrop={true}
      >
        <Offcanvas.Header className="pb-0 mb-0">
          <Offcanvas.Title>
            <h3 style={{ cursor: "pointer" }} onClick={handleCloseEditProfile}>
              <i className="las la-arrow-left"></i>{" "}
            </h3>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {(details.pageState === AppState.ERROR ||
            details.pageState === AppState.ERROR_INPUT) && (
            <NotifyAlert
              message={details.message}
              state={AppState.ERROR}
              timer={10000}
            />
          )}
          <Form onSubmit={handleSubmitEditProfile}>
            <h4 style={{ fontSize: "24px" }}>
              <Languages langKey="updateProfile" />
            </h4>
            <div className="d-flex justify-content-start align-items-center gap-2 pt-3">
              <div>
                {" "}
                <label
                  className="profile-picture mx-auto bg-danger"
                  htmlFor="profileImage"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",

                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      cursor: "pointer",
                    }}
                    src={
                      typeof user?.image_url === "string" && !profilePicture
                        ? user?.image_url
                        : URL.createObjectURL(profilePicture ?? null!)
                    }
                    alt=""
                    className="profile-picture mx-auto bg-danger"
                  />
                </label>
              </div>

              {profilePicture ? (
                <div className="d-flex gap-3">
                  <Button
                    variant="transparent"
                    className="text-danger p-0 m-0"
                    style={{ cursor: "pointer" }}
                    disabled={details.pageState === AppState.LOADING}
                    onClick={() => setProfilePicture(null!)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="transparent"
                    disabled={details.pageState === AppState.LOADING}
                    className="text-danger p-0 m-0"
                    onClick={upload}
                  >
                    Save
                  </Button>
                </div>
              ) : (
                <div>
                  <label
                    htmlFor="profileImage"
                    className="fw-bold"
                    style={{ cursor: "pointer", color: "#DA3732" }}
                  >
                    Choose an image <i className="las la-angle-right"></i>
                  </label>
                  <input
                    onChange={({ target: { files } }) =>
                      setProfilePicture(files![0])
                    }
                    type="file"
                    className="form-control d-none"
                    id="profileImage"
                  />
                </div>
              )}
            </div>
            <Row className="align-items-center pt-4">
              <Form.Group className="form-group col-sm-12 col-md-6*">
                <Form.Label htmlFor="first_name" className="form-label">
                  <Languages langKey="firstName" />:
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="first_name"
                  defaultValue={user?.first_name}
                  onChange={({ target: { value } }) =>
                    setUser((prev) => ({ ...prev!, first_name: value }))
                  }
                  required
                />
              </Form.Group>
              <Form.Group className="form-group col-sm-12 col-md-6*">
                <Form.Label htmlFor="last_name" className="form-label">
                  <Languages langKey="lastName" />:
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="last_name"
                  defaultValue={user?.last_name}
                  onChange={({ target: { value } }) =>
                    setUser((prev) => ({ ...prev!, last_name: value }))
                  }
                  required
                />
              </Form.Group>


              <Form.Group className="form-group col-sm-12 col-md-6*">
                <Form.Label htmlFor="username" className="form-label">
                  <Languages langKey="username" />
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                    type="text"
                    className="form-control"
                    id="last_name"
                    defaultValue={user?.username}
                    onChange={({ target: { value } }) =>
                        setUser((prev) => ({ ...prev!, username: value }))
                    }
                    required
                />
              </Form.Group>



              <Form.Group className="form-group col-sm-12 col-md-6*">
                <Form.Label htmlFor="country" className="form-label">
                  <Languages langKey="country" />:
                </Form.Label>
                <Form.Select
                  className="form-control"
                  defaultValue={user?.country}
                  onChange={({ target: { value } }) =>
                    setUser((prev) => ({ ...prev!, country: value }))
                  }
                  required
                >
                  {Countries.map((country: string, i) => (
                    <option value={country} key={i}>
                      {" "}
                      {country}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="form-group col-sm-12 col-md-6*">
                <Form.Label htmlFor="city" className="form-label">
                  <Languages langKey="city" />:
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="city"
                  defaultValue={user?.city}
                  onChange={({ target: { value } }) =>
                    setUser((prev) => ({ ...prev!, city: value }))
                  }
                  required
                />
              </Form.Group>
              <Form.Group className="form-group col-sm-12">
                <Form.Label htmlFor="email" className="form-label">
                  <Languages langKey="email" />:
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-control"
                  id="email"
                  defaultValue={user?.email}
                  onChange={({ target: { value } }) =>
                    setUser((prev) => ({ ...prev!, email: value }))
                  }
                />
              </Form.Group>
              <Form.Group className="form-group col-sm-12">
                <Form.Label htmlFor="institute" className="form-label">
                  <Languages langKey="institute" />:
                </Form.Label>
                {!CheckInstitutionsExist() ? (
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="institute"
                    defaultValue={user?.institute}
                    onChange={({ target: { value } }) =>
                      setUser((prev) => ({ ...prev!, institute: value }))
                    }
                  />
                ) : (
                  <Form.Select
                    onChange={({ target: { value } }) =>
                      setUser((prev) => ({ ...prev!, institute: value }))
                    }
                    defaultValue={user?.institute}
                  >
                    <option disabled value={""}>
                      --Select Institute--
                    </option>
                    {getInstitutions().map((item: string, i: number) => (
                      <option key={i}>{item}</option>
                    ))}
                  </Form.Select>
                )}
              </Form.Group>

              <Form.Group className="form-group col-sm-12">
                <Form.Label htmlFor="about" className="form-label">
                  <Languages langKey="about" />
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={getLanguage.tellUsAboutYourself}
                  onChange={({ target: { value } }) =>
                    setUser((prev) => ({ ...prev!, about: value }))
                  }
                  defaultValue={user?.about}
                />
              </Form.Group>
            </Row>
            <div className="d-grid">
              <Button
                type="submit"
                className="btn btn-primary ms-2"
                disabled={details.pageState === AppState.LOADING}
              >
                {details.pageState === AppState.LOADING && (
                  <Spinner animation={"border"} size={"sm"} className="me-1" />
                )}
                <Languages langKey="update" />
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>{" "}
    </>
  );
};

export default EditProfile;
