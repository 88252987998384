import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import {
  InterestResponse,
  PaidSubscriptionResponse,
  PointResponse,
  UserResponse,
  ClassResponse,
} from "../../services/api.response.service";
import { AppState, SetToken } from "../../services/app.service";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";

import Setting from "../../redux/actions/setting.action";
// import NotifyAlert from "../../components/NotifyAlert";
// import InterestOffCanvas from "../../components/InterestOffCanvas";
// import InterestClassesOffCanvas from "../../components/InterestClassesOffcanvas";
// import Languages from "../../components/Languages";
// import { Countries } from "../../utils/constants";
// import { url } from "inspector";
import UserDetailsCard from "../../components/Profile/UserDetailsCard";
import UserProfile from "../../components/Profile/UserProfile";
import Settings from "../../components/Profile/Settings";
import EditProfile from "../../components/Profile/EditProfileCanvas";
import UpdatePassword from "../../components/Profile/UpdatePassword";

function Profile() {
  // const tab = location.hash.replace('#','')
  const dispatch = useDispatch();

  const state = useSelector((state: IRootState) => state.main);

  const details: BaseState<UserResponse> = state[StateKeys.PROFILE];
  const userInterests: BaseState<Array<InterestResponse>> =
    state[StateKeys.USER_INTERESTS];
  const userPoints: BaseState<Array<PointResponse>> =
    state[StateKeys.USER_POINTS];
  const userPayments: BaseState<Array<PaidSubscriptionResponse>> =
    state[StateKeys.USER_PAYMENTS];
  const interests: BaseState<Array<InterestResponse>> =
    state[StateKeys.INTERESTS];

  const [showEditProfile, setShowEditProfile] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);

  // const [interestCanvas, setInterestCanvas] = useState<boolean>(false);
  // const [InterestClassesCanvas, setInterestClassesCanvas] = useState<string>();

  useEffect(() => {
    if (details.pageState === AppState.LOADED) {
      SetToken(details.data);
      // dispatch(Main.togglePageState({stateKey: StateKeys.PROFILE, data: AppState.IDLE}))
      dispatch(Setting.setUser(details.data));
      dispatch(
        Main.togglePageState({
          stateKey: StateKeys.PROFILE,
          data: AppState.IDLE,
        })
      );
      handleCloseEditProfile();
      handleShowChangePassword();
    }
  }, [details.pageState]);

  useEffect(() => {
    if (
      details.pageState !== AppState.LOADED &&
      userInterests.pageState !== AppState.LOADING
    ) {
      dispatch(
        Main.getPageObjectData({
          stateKey: StateKeys.PROFILE,
          apiEndpoint: ApiEndpoint.USER,
        })
      );
    }
    if (
      userInterests.pageState !== AppState.LOADED &&
      userInterests.pageState !== AppState.LOADING
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.USER_INTERESTS,
          apiEndpoint: ApiEndpoint.USER_INTERESTS,
          previousPage: userInterests.currentPage!,
        })
      );
    }
    if (
      userPoints.pageState !== AppState.LOADED &&
      userPoints.pageState !== AppState.LOADING
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.USER_POINTS,
          apiEndpoint: ApiEndpoint.USER_POINTS,
          previousPage: userPoints.currentPage!,
        })
      );
    }
    if (
      userPayments.pageState !== AppState.LOADED &&
      userPayments.pageState !== AppState.LOADING
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.USER_PAYMENTS,
          apiEndpoint: ApiEndpoint.USER_PAID_SUBSCRIPTIONS,
          previousPage: userPayments.currentPage!,
        })
      );
    }
    if (
      interests.pageState !== AppState.LOADED &&
      interests.pageState !== AppState.LOADING
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.INTERESTS,
          apiEndpoint: ApiEndpoint.ALL_INTERESTS,
          previousPage: interests.currentPage!,
        })
      );
    }
  }, []);

  // useEffect(() => {
  //   setInterestCanvas(false);
  // }, [userInterests.data]);

  // useEffect(() => {
  //   if (InterestClassesCanvas) {
  //     dispatch(
  //       Main.getPageArrayData({
  //         stateKey: StateKeys.ACTIVE_CLASSES,
  //         apiEndpoint: ApiEndpoint.ACTIVE_CLASSES(InterestClassesCanvas),
  //         clear: true,
  //       })
  //     );
  //   }
  // }, [InterestClassesCanvas]);

  const handleCloseEditProfile = () => setShowEditProfile(false);
  const handleShowChangePassword = () => setOpenPassword(false);

  // const handleShowEditProfile = () => setShowEditProfile(true);
  // const handleCloseChangePassword = () => setShowChangePassword(false);

  // useEffect(() => {
  //   handleCloseEditProfile();
  //   handleShowChangePassword();
  // }, [details.data]);

  return (
    <div className="content-page scroller-hide">
      <Row>
        <Col sm={12}>
          <h1 className="mb-4 profile-head">My Profile</h1>

          <UpdatePassword
            openPassword={openPassword}
            handleShowChangePassword={handleShowChangePassword}
            details={details}
          />

          <UserProfile
            details={details.data}
            setShowEditProfile={setShowEditProfile}
          />

          <UserDetailsCard details={details.data} />

          <Settings setOpenPassword={setOpenPassword} />
        </Col>
      </Row>
      <EditProfile
        showEditProfile={showEditProfile}
        handleCloseEditProfile={handleCloseEditProfile}
        details={details}
      />
    </div>
  );
}

export default Profile;
