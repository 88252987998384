import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { StateKeys } from "../../redux/states/main.state";
import {
  AppPageScrollDownLoadMore,
  AppState,
} from "../../services/app.service";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";
import Loader from "../../components/Loader";
import Card from "../../components/Card";
import { CategoryResponse } from "../../services/api.response.service";
import { RouteEndpoints } from "../../services/route.service";
import { Link } from "react-router-dom";
import NoData from "./NoData";
import Languages from "../../components/Languages";

function Categories() {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);

  const { data, pageState, hasMore, currentPage } = state[StateKeys.CATEGORIES];

  useEffect(() => {
    if (pageState !== AppState.LOADED && pageState !== AppState.LOADING) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CATEGORIES,
          apiEndpoint: ApiEndpoint.ALL_CATEGORIES,
          previousPage: currentPage!,
        })
      );
    }
  }, []);

  const loadMore = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
        // @ts-ignore
        document.scrollingElement.scrollHeight &&
      hasMore &&
      // @ts-ignore
      (pageState !== AppState.LOADING || pageState !== AppState.LOADING_MORE)
    ) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CATEGORIES,
          apiEndpoint: ApiEndpoint.ALL_CATEGORIES,
          previousPage: currentPage!,
        })
      );
    }
  };

  return (
    <div
      className="content-page scroller-hide"
      onScroll={(e) => AppPageScrollDownLoadMore(e, loadMore)}
    >
      <Row className="content-card pe-4 mt-4*">
        <h5
            style={{
              fontSize: "24px",
              fontWeight: 400,
            }}
        >
          <Languages langKey="categories" />
        </h5>
        <Col sm={12} className="row mt-4 p-0">
          {(pageState === AppState.LOADED ||
            pageState === AppState.LOADING_MORE) && (
            <>
              {data.length > 0 ? (
                (data as Array<CategoryResponse>).map(
                  ({ id, name, total_classes, level, link_name }, i) => (
                    <Col key={i} sm={2} className="mt-3">
                      {/* <Link
                        to={RouteEndpoints.CATEGORY_CLASSES(String(id))}
                        className="text-decoration-none"
                      >
                        <Card>
                          <Card.Body>
                            <h5>{name}</h5>
                            <small
                              className="text-secondary d-flex align-items-center gap-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Total Available Classes"
                            >
                              <i
                                className="las la-university"
                                style={{ fontSize: 18 }}
                              />
                              <span className="text-primary">
                                {total_classes}
                              </span>
                            </small>
                 
                          </Card.Body>
                        </Card>
                      </Link> */}

                      <Link
                        // to={RouteEndpoints.CATEGORY_CLASSES(`${id}`)}
                        to={RouteEndpoints.CATEGORY_DETAILS(`${link_name}`)}
                        className="text-decoration-none"
                      >
                        <Card className="interest mb-0 d-flex justify-content-center align-items-center">
                          <Card.Body>
                            <h5 className="text-white"> {name}</h5>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  )
                )
              ) : (
                <NoData title="noCatAvailable" />
              )}
            </>
          )}
        </Col>
        <Col md={12}>
          {hasMore &&
            !(
              pageState === AppState.LOADING ||
              pageState === AppState.LOADING_MORE
            ) && (
              <div
                className={`w-100 text-center shadow-xss rounded-xxl border-0 px-3`}
              >
                <button
                  className="text-decoration-none btn btn-primary"
                  onClick={() => loadMore()}
                >
                  <Languages langKey="loadMore" />
                </button>
              </div>
            )}
        </Col>
        <Col md={12}>
          {(pageState === AppState.LOADING_MORE ||
            pageState === AppState.LOADING) && <Loader noCard imageLoader />}
        </Col>
      </Row>
    </div>
  );
}

export default Categories;
