import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import {
  CourseResponse,
  SubscriptionResponse,
  TestResponse,
  TestResultResponse,
} from "../../services/api.response.service";
import GoBack from "../GoBack";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/states";
import { useParams } from "react-router";
import Main from "../../redux/actions/main.action";
import {
  AppState,
  CheckContentProviderIdExist,
  GetFullURL,
  getLanguageByDefault,
} from "../../services/app.service";
import ApiEndpoint from "../../services/api.endpoint.service";
import Loader from "../Loader";
import Languages from "../Languages";
import TestResults from "./TestResults";

// interface props {
//   results: BaseState<TestResponse>;
// }

const Test = () => {
  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);
  const test: BaseState<TestResponse> = state[StateKeys.CLASS_TEST];
  const testResults: BaseState<Array<TestResultResponse>> =
    state[StateKeys.CLASS_TEST_RESULTS];
  const { obj: course, pageState }: BaseState<Array<CourseResponse>> =
    state[StateKeys.CLASS_COURSES];
  const [showTestResults, setShowTestResults] = useState(false);
  const [showTest, setShowTest] = useState(false);
  const [stopTest, setStopTest] = useState(false);
  const getLanguage = getLanguageByDefault();

  const subscriptions: BaseState<Array<SubscriptionResponse>> =
    state[StateKeys.USER_SUBSCRIPTION];
  const subscription =
    subscriptions.data.length > 0
      ? subscriptions.data.find(
          (e) => e.subscriber_class.id === test?.data?.test_class?.id
        )
      : undefined;

  useEffect(() => {
    // if (test.pageState !== AppState.LOADED) {
    // if (
    //   dataPersist.data &&
    //   dataPersist.data[StateKeys.CLASS_TEST.concat("-", id)]
    // ) {
    //   dispatch(
    //     Main.getPageObjectData({
    //       stateKey: StateKeys.CLASS_TEST,
    //       apiEndpoint: ApiEndpoint.CLASS_TEST(Number(id)),
    //       // storeKey: StateKeys.CLASS_TEST.concat("-", id),
    //       clear: true,
    //     })
    //   );
    //   dispatch(
    //     Main.togglePageState({
    //       stateKey: StateKeys.CLASS_TEST,
    //       data: dataPersist.data[StateKeys.CLASS_TEST.concat("-", id)]
    //         .pageState,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     Main.postPageObjectDataSuccess({
    //       stateKey: StateKeys.CLASS_TEST,
    //       data: {},
    //       clear: true,
    //     })
    //   );
    dispatch(
      Main.getPageObjectData({
        stateKey: StateKeys.CLASS_TEST,
        apiEndpoint: ApiEndpoint.CLASS_TEST(Number(id)),
        // storeKey: StateKeys.CLASS_TEST.concat("-", id),
      })
    );

    // }
    // }
  }, [id]);

  useEffect(() => {
    if (test?.data?.test_class?.id) {
      dispatch(
        Main.getPaginatedPageData({
          stateKey: StateKeys.CLASS_COURSES,
          apiEndpoint: ApiEndpoint.CLASS_COURSES(
            (test?.data?.test_class?.id)
          ),
          previousPage: 0,
          obj: true,
          storeKey: StateKeys.CLASS_COURSES.concat(
            "-",
            `${test?.data?.test_class?.id}`
          ),
        })
      );
    }
  }, [test.data]);

  useEffect(() => {
    if (showTestResults && testResults.data.length === 0) {
      dispatch(
        Main.getPageArrayData({
          stateKey: StateKeys.CLASS_TEST_RESULTS,
          apiEndpoint: ApiEndpoint.CLASS_TEST_RESULTS(Number(test.data?.id)),
          storeKey: StateKeys.CLASS_COURSE_TOPICS.concat(
            "-",
            String(test.data?.id)
          ),
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTestResults]);

  const subscribe = () => {
    const subscribed = course?.is_subscribed;

    subscribed
      ? dispatch(
          Main.postPageData({
            stateKey: StateKeys.USER_SUBSCRIPTION,
            apiEndpoint: ApiEndpoint.UNSUBSCRIBE_CLASS(
              Number(test?.data?.test_class?.id)
            ),
            req: "",
            removeAt: subscription
              ? subscriptions.data.indexOf(subscription)
              : undefined,
          })
        )
      : dispatch(
          Main.postPageData({
            stateKey: StateKeys.USER_SUBSCRIPTION,
            apiEndpoint: ApiEndpoint.SUBSCRIBE_CLASS(
              Number(test?.data?.test_class?.id)
            ),
            req: "",
            append: "start",
          })
        );

      window.location.reload();
  };

  return (
    <>
      {showTest ? (
        <div
          onClick={() => setStopTest(true)}
          className="pb-4"
          style={{ cursor: "pointer", width: "fit-content" }}
        >
          <h3>
            <i className="las la-arrow-left"></i>
          </h3>
        </div>
      ) : (
        <GoBack />
      )}
      {test.pageState === AppState.LOADING ? (
        <Loader noCard imageLoader />
      ) : (
        <div className="d-flex flex-column gap-4">
          <h5
            style={{
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            {test?.data?.name}
          </h5>

          {showTest ? (
            <iframe
              src={GetFullURL(test?.data?.test_url)}
              style={{ width: "100%", height: "100vh" }}
              title="test"
            />
          ) : (
            <>
              <div className="test-card-details-container">
                <Card className="d-flex justify-content-center align-items-center user-card p-1">
                  <small>Duration</small>
                  <h6 className="font-weight-bold">{test.data?.duration}</h6>
                </Card>
                <Card className="d-flex justify-content-center align-items-center user-card p-1">
                  <small>Pass Mark</small>
                  <h6>{test?.data?.pass_mark ?? "NA"}</h6>
                </Card>
                <Card className="d-flex justify-content-center align-items-center user-card p-1">
                  <small>Questions Limit</small>
                  <h6>{test?.data?.question_limit ?? "NA"}</h6>
                </Card>
                <Card className="d-flex justify-content-center align-items-center user-card p-1">
                  <small>Start Date</small>
                  <h6>
                    {new Date(test?.data?.start_date ?? "").toLocaleDateString(
                      getLanguage.dateTimeLanguage,
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )}
                  </h6>
                </Card>
                <Card className="d-flex justify-content-center align-items-center user-card p-1">
                  <small>Start Time</small>
                  <h6>{test.data?.start_time}</h6>
                </Card>
                <Card className="d-flex justify-content-center align-items-center user-card p-1">
                  <small>End Date</small>
                  <h6>
                    {new Date(test?.data?.end_date ?? "").toLocaleDateString(
                      getLanguage.dateTimeLanguage,
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )}
                  </h6>
                </Card>
                <Card className="d-flex justify-content-center align-items-center user-card p-1">
                  <small>End Time</small>
                  <h6>{test.data?.end_time}</h6>
                </Card>
              </div>
              <>
                <div>
                  <h6
                    style={{
                      fontSize: "12px",
                      fontWeight: 700,
                    }}
                  >
                    Instructions
                  </h6>
                  <p>{test.data?.instructions}</p>
                </div>

                {pageState === AppState.LOADING ? (
                  <Loader noCard imageLoader />
                ) : (
                  <div className="d-flex gap-3">
                    {subscription?.is_subscribed || course?.is_subscribed ? (
                      subscription?.has_paid ||
                      course?.has_paid ||
                      CheckContentProviderIdExist() ? (
                        <Button
                          className="btn rounded"
                          variant="primary"
                          onClick={() => setShowTest(true)}
                        >
                          <Languages langKey="takeTest" />
                        </Button>
                      ) : (
                        <a
                          href={course?.payment_url}
                          target="_blank"
                          className="text-decoration-none btn btn-primary"
                          rel="noreferrer"
                        >
                          <Languages langKey="payToAccessContent" />
                        </a>
                      )
                    ) : (
                      <button
                        className="text-decoration-none btn btn-primary"
                        onClick={() =>
                          CheckContentProviderIdExist() ? null : subscribe()
                        }
                        disabled={
                          subscriptions?.pageState === AppState.LOADING ||
                          CheckContentProviderIdExist()
                        }
                      >
                        {subscriptions?.pageState === AppState.LOADING && (
                          <Spinner
                            animation={"border"}
                            size={"sm"}
                            className="me-1"
                          />
                        )}
                        {CheckContentProviderIdExist() ? (
                          <Languages langKey="contentAdminTest" />
                        ) : (
                          <Languages langKey="subToStartLearn" />
                        )}
                      </button>
                    )}

                    <Button
                      className="btn rounded"
                      variant="outline-primary"
                      onClick={() => setShowTestResults((prev) => !prev)}
                      disabled={testResults.pageState === AppState.LOADING}
                    >
                      {showTestResults ? "Hide" : "View"} previous test results
                      {testResults.pageState === AppState.LOADING && (
                        <Spinner
                          animation={"border"}
                          size={"sm"}
                          className="me-1"
                        />
                      )}
                    </Button>
                  </div>
                )}
              </>
            </>
          )}

          {showTestResults && (
            <Row>
              {(testResults.pageState === AppState.LOADED ||
                testResults.pageState === AppState.LOADING_MORE) && (
                <TestResults
                  testResults={testResults}
                  test={test?.data}
                  setShowTest={setShowTest}
                />
              )}
              <Col md={12}>
                {testResults.pageState === AppState.LOADING && (
                  <Loader noCard imageLoader />
                )}
              </Col>
            </Row>
          )}
        </div>
      )}

      <Modal
        show={stopTest}
        centered
        size="sm"
        onHide={() => setStopTest(false)}
      >
        <Modal.Body>
          <p>Are you certain you wish to exit this test page?</p>

          <div className="d-grid gap-3">
            <Button variant="primary" onClick={() => setStopTest(false)}>
              Stay on Page
            </Button>{" "}
            <Button
              variant="outline-primary"
              onClick={() => {
                setShowTest(false);
                setStopTest(false);
              }}
            >
              Exist{" "}
            </Button>{" "}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Test;
