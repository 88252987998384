import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Col } from "react-bootstrap";
import ClassCard2 from "./ClassCard2";
import { ClassResponse } from "../services/api.response.service";
import { AppState } from "../services/app.service";
import Loader from "./Loader";
import Card from "./Card";
import Languages from "./Languages";

interface Props {
  viewAllLink?: string;
  data: Array<ClassResponse>;
  state: AppState;
  language?: string;
}

function ClassCardRList({ viewAllLink, data, state, language }: Props) {
  const location = useLocation();

  // console.log(data);
  return (
    <>
      <Col lg={12} className="row m-0 p-0 ">
        <Col sm={12} className="d-flex justify-content-between mt-1">
          <div className="header-title">
            <h4 className="card-title" style={{ fontWeight: 500 }}>
              <Languages langKey={"recent"} />
            </h4>
          </div>
          {viewAllLink && state === AppState.LOADED && (
            <div className="card-header-toolbar d-flex align-items-center">
              <Link to={viewAllLink}>
                <Languages langKey={"viewAll"} />
                <i className="las la-angle-right"></i>
              </Link>
            </div>
          )}
        </Col>
        <Col
          sm={12}
          className={`${
            location.pathname === "/home"
              ? "cards-container flex-row flex-nowrap "
              : "row"
          }  m-0 p-0`}
          style={{
            display: location.pathname === "/home" ? "grid" : "",
            gridTemplateColumns:
              location.pathname === "/home"
                ? `repeat(${
                    location.pathname === "/home" ? data.length + 1 : 6
                  }, 200px)`
                : "",
            gridGap: location.pathname === "/home" ? "20px" : "",
          }}
        >
          {state === AppState.LOADING ? (
            <Loader noCard imageLoader />
          ) : data.length > 0 ? (
            data.map((item) => (
              <Col
                key={item.id}
                className={`mt-1 ${
                  location.pathname === "/home"
                    ? ""
                    : "col-12 col-sm-6 col-md-4 col-lg-3"
                }`}
              >
                <ClassCard2 recent={item} />
              </Col>
            ))
          ) : (
            <Card>
              <Card.Body className="text-center">
                <p>
                  <Languages langKey={"noData"} />
                </p>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Col>
    </>
  );
}

export default ClassCardRList;
