import { Col, Row } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../redux/states";
import { StateKeys } from "../redux/states/main.state";
import { ResourceResponse } from "../services/api.response.service";
import { AppState, InfoLogger } from "../services/app.service";
import Main from "../redux/actions/main.action";
import { CalculateUserMediaPoints } from "../utils/extraFunctions";
import ApiEndpoint from "../services/api.endpoint.service";

function AudioPlayer() {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);
  const video = state[StateKeys.VIDEO];
  const audio = state[StateKeys.AUDIO];
  const audioRef = useRef<HTMLAudioElement>(null);
  const [giveUserPoint, setGiveUserPoint] = useState<boolean>(false);

  const handleAudioPlaying = () => {
    dispatch(
      Main.togglePageState({
        stateKey: StateKeys.VIDEO,
        data: AppState.PAUSING,
      })
    );
    !!audio.data.id && audioRef.current && audioRef.current.play();
    handleAudioState(AppState.PLAY);
  };

  const handleAudioPause = () => {
    !!audio.data.id && audioRef.current && audioRef.current.pause();
    handleAudioState(AppState.PAUSED);
  };

  const handleAudioState = (value: AppState) =>
    dispatch(Main.togglePageState({ stateKey: StateKeys.AUDIO, data: value }));

  useEffect(() => {
    if (audio.pageState === AppState.PLAYING) {
      handleAudioPlaying();
    }

    if (
      audio.pageState === AppState.PAUSING ||
      video.pageState === AppState.PLAYING
    ) {
      handleAudioPause();
    }
  }, [audio, video]);

  const { content_id, id } = audio.data;

  useEffect(() => {
    InfoLogger("Trigger audio point", giveUserPoint);
    if (!!content_id) {
      dispatch(
        Main.postPageData({
          stateKey: StateKeys.USER_POINTS,
          apiEndpoint: ApiEndpoint.POINTS,
          req: { where: "listen", content_id, resource_id: id, points: 1 },
        })
      );
    }
  }, [giveUserPoint]);

  return (
    <Row>
      {!!audio.data.id && (
        <Col sm={12}>
          <audio
            id="audio"
            ref={audioRef}
            className="w-100"
            autoPlay
            controls
            controlsList="nodownload"
            key={(audio.data as ResourceResponse).id}
            onPause={() => handleAudioState(AppState.PAUSING)}
            onPlay={() => {
              handleAudioState(AppState.PLAYING);
              CalculateUserMediaPoints(setGiveUserPoint, "#audio");
            }}
            onEnded={() => setGiveUserPoint(true)}
          >
            <source
              src={(audio.data as ResourceResponse).url}
              type="audio/mp3"
              title={(audio.data as ResourceResponse).description}
            />
          </audio>
        </Col>
      )}
    </Row>
  );
}

export default AudioPlayer;
